<template>
    <v-pagination
        v-model="page"
        :length="pages"
        :total-visible="7"
        :color="cardSettings.buttonBackgroundColor"
    ></v-pagination>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        amount: Number,
    },
    data() {
        return {
            page: null,
        };
    },
    mounted() {
        // Set current page stored in localStorage (coming back from coupon)
        if (localStorage.getItem("current-page")) {
            this.$store.commit(
                "directory/SET_CURRENT_PAGE",
                parseInt(localStorage["current-page"])
            );

            localStorage.removeItem("current-page");
        } else {
            this.page = this.currentPage;
        }
    },
    computed: {
        cardSettings() {
            return this.$store.getters["directory/getDirectory"].settingsCard;
        },
        currentPage() {
            return this.$store.getters["directory/getCurrentPage"];
        },
        pages() {
            if (isNaN(this.cardSettings.paginationAmount)) return 0;

            return Math.ceil(this.amount / this.cardSettings.paginationAmount);
        },
    },
    watch: {
        currentPage: function (value) {
            this.page = value;
        },
        page: function (value) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            this.$store.commit("directory/SET_CURRENT_PAGE", value);
        },
    },
};
</script>

<style>
</style>
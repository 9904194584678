import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { toCamelCase, toSnakeCase } from "../namingConversion.js";

Vue.use(Vuex);

const userModule = {
	namespaced: true,
	state: {
		user: null,
		coordinates: null,
	},
	mutations: {
		SET_USER(state, value) {
			state.user = toCamelCase(value);
		},

		SET_COORDINATES(state, value) {
			state.coordinates = value;
		}
	},
	actions: {
		me({ commit, rootGetters }) {
			return new Promise((resolve, reject) => {
				let queryParams = "?id=" + rootGetters["directory/getId"];

				const urlParams = new URLSearchParams(window.location.search);

				if (urlParams.has('utm_medium')) queryParams += "&utm_medium=" + urlParams.get('utm_medium')
				if (urlParams.has('utm_source')) queryParams += "&utm_source=" + urlParams.get('utm_source')
				if (urlParams.has('utm_campaign')) queryParams += "&utm_campaign=" + urlParams.get('utm_campaign')
				if (urlParams.has('utm_term')) queryParams += "&utm_term=" + urlParams.get('utm_term')
				if (urlParams.has('utm_content')) queryParams += "&utm_content=" + urlParams.get('utm_content')

				axios.get("/user/me" + queryParams, headers(rootGetters))
					.then((resp) => {
						// Check if user is blocked
						if (resp.data.blocked) {
							commit("auth/SET_AUTHENTICATED", false, { root: true });
							commit("SET_USER", null);

							reject();
						} else {
							commit("auth/SET_AUTHENTICATED", true, { root: true });
							commit("SET_USER", resp.data);

							resolve();
						}
					})
					.catch(() => {
						commit("auth/SET_AUTHENTICATED", false, { root: true });
						commit("SET_USER", null);

						reject();
					});
			});
		},
		update({ commit, rootGetters }, data) {
			// Copy data to make sure component data is unchanged
			data = toSnakeCase(Object.assign({}, data));

			return new Promise((resolve) => {
				axios.put("/user", data, headers(rootGetters))
					.then((resp) => {
						commit("SET_USER", resp.data);

						resolve();
					});
			});
		},
		delete({ commit, rootGetters }) {
			return new Promise((resolve) => {
				axios.delete("/user", headers(rootGetters))
					.then(() => {
						commit("auth/SET_AUTHENTICATED", false, { root: true });
						commit("SET_USER", null);

						resolve();
					});
			});
		},
		// Retrieve user directories on delete account
		directories({ rootGetters }) {
			return new Promise((resolve) => {
				axios.get("/user/directories", headers(rootGetters))
					.then((resp) => {
						let directories = toCamelCase(resp.data);

						resolve(directories);
					})
			});
		},
		coordinates({ commit }) {
			if (!navigator.geolocation) return;

			var options = {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			};

			// Shows the "Allow xxx to access your location?" popup
			navigator.geolocation.getCurrentPosition(
				function (pos) {
					commit("SET_COORDINATES", [pos.coords.latitude, pos.coords.longitude]);
				},
				null,
				options
			);
		}
	},
	getters: {
		getUser(state) {
			return state.user;
		},
		getId(state) {
			return state.user.id;
		},
		getCoordinates(state) {
			return state.coordinates;
		}
	},
};

function headers(getters) {
	let headers = { Authorization: `Bearer ${getters["auth/getAccessToken"]}` };

	return { headers: headers }
}


export default userModule;

import Vue from 'vue'
import axios from "axios";
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import i18n from "./plugins/i18n";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import './assets/css/main.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { VueMasonryPlugin } from 'vue-masonry';

import UniversalSocialauth from 'universal-social-auth';
import './registerServiceWorker'

axios.defaults.baseURL = process.env.VUE_APP_DIRECTORY_API + "/api/client";

const options = {
	providers: {
		google: {
			clientId: process.env.VUE_APP_SOCIAL_GOOGLE_CLIENT_ID,
			//redirectUri: process.env.VUE_APP_SOCIAL_GOOGLE_REDIRECT_URI
		},
		facebook: {
			clientId: process.env.VUE_APP_SOCIAL_FACEBOOK_CLIENT_ID,
			//redirectUri: process.env.VUE_APP_SOCIAL_FACEBOOK_REDIRECT_URI
		},
		twitter: {
			url: 'https://myapp.com/auth/twitter',
			clientId: process.env.VUE_APP_SOCIAL_TWITTER_CLIENT_ID,
			redirectUri: process.env.VUE_APP_SOCIAL_TWITTER_REDIRECT_URI
		}
	}
}

Vue.prototype.$Oauth = new UniversalSocialauth(axios, options)

Vue.use(VueTelInput);
Vue.use(VueMasonryPlugin)

Vue.config.productionTip = false

new Vue({
	vuetify,
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')

<template>
	<v-dialog v-model="dialog" min-width="500">
		<v-card>
			<v-card-title>
				<v-btn
					class="dialog__close"
					icon
					x-small
					@click.native="dialog = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="text-center">
				<v-icon class="mb-4" :color="paymentColor" size="132">
					{{ paymentIcon }}
				</v-icon>

				<v-data-table
					:headers="headers"
					:items="user.payments"
					hide-default-footer
					dense
					:no-data-text="$t('payments.not-found')"
				>
					<template v-slot:[`item.paidAt`]="{ item }">
						{{ parseDate(item.paidAt) }}
					</template>
					<template v-slot:[`item.expiresAt`]="{ item }">
						{{ parseDate(item.expiresAt) }}
					</template>
					<template v-slot:[`item.category`]="{ item }">
						{{ getCategoryName(item.paymentOption) }}
					</template>
					<template v-slot:[`item.paymentMethod`]="{ item }">
						{{ getPaymentMethod(item.paymentMethod) }}
					</template>
					<template v-slot:[`item.value`]="{ item }">
						{{ getValue(item) }}
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<!-- Receipt url -->
						<a
							v-if="item.receiptUrl"
							:href="item.receiptUrl"
							target="_blank"
						>
							<v-icon color="black">mdi-receipt-text</v-icon>
						</a>

						<!-- Invoice url -->
						<a
							v-if="item.invoiceUrl"
							:href="item.invoiceUrl"
							target="_blank"
						>
							<v-icon color="black">mdi-receipt-text</v-icon>
						</a>
					</template>
				</v-data-table>

				<v-btn
					v-if="showBillingPortal"
					class="mt-3"
					:style="buttonStyleIgnoreWidth"
					:loading="loading"
					depressed
					@click="initStripePortal"
				>
					{{ $t("payments.manage-payments") }}
				</v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import categoryHelpers from "@/mixins/categoryHelpers.js";
import layoutHelpers from "@/mixins/layoutHelpers.js";
import PaymentMethod from "../../enums/PaymentMethod.js";

export default {
	name: "PaymentStatusDialog",
	data() {
		return {
			dialog: false,
			loading: false,

			headers: [
				{ text: this.$t("payments.purchase-date"), value: "paidAt" },
				{
					text: this.$t("payments.payment-method"),
					value: "paymentMethod",
				},
				{ text: this.$t("payments.value"), value: "value" },
			],
		};
	},
	mixins: [categoryHelpers, layoutHelpers],
	mounted() {
		if (this.paymentSettings.paymentRestriction == "category")
			this.headers.splice(1, 0, {
				text: this.$t("payments.category"),
				value: "category",
			});

		if (this.paymentSettings.paymentStripe)
			this.headers.push({ text: "", value: "action", sortable: false });

		if (this.paymentSettings.recurring != "one-time")
			this.headers.splice(1, 0, {
				text: this.$t("payments.expiry-date"),
				value: "expiresAt",
			});
	},
	computed: {
		user() {
			return this.$store.getters["user/getUser"];
		},
		paymentSettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsPayment;
		},
		paymentIcon() {
			return this.hasActivePayment
				? "mdi-checkbox-marked-circle-outline"
				: "mdi-close-circle-outline";
		},
		paymentColor() {
			return this.hasActivePayment ? "green" : "red";
		},
		hasActivePayment() {
			const now = new Date();

			// Check if expired
			let activePayment = this.user.payments.find((obj) => {
				return (
					obj.status == "paid" &&
					(obj.expiresAt == null ||
						obj.expiresAt >= now.toISOString())
				);
			});

			return activePayment != null;
		},
		showBillingPortal() {
			// Only show billing portal for Stripe recurring payments
			return (
				this.paymentSettings.paymentType != "one-time" &&
				this.paymentSettings.paymentStripe &&
				this.user.stripeCustomerId
			);
		},
	},
	methods: {
		open() {
			this.dialog = true;
		},
		parseDate(date) {
			if (date == null) return "";

			let parsedDate = new Date(date);

			return parsedDate.toLocaleDateString();
		},
		getPaymentMethod(paymentMethod) {
			if (paymentMethod === PaymentMethod.STRIPE) {
				return this.$t("payments.method-stripe");
			} else if (paymentMethod === PaymentMethod.PAYSTACK) {
				return this.$t("payments.method-paystack");
			} else if (paymentMethod === PaymentMethod.PROMOCODE) {
				return this.$t("payments.method-access-code");
			} else if (paymentMethod === PaymentMethod.CUSTOM) {
				return this.$t("payments.method-custom");
			}

			return "";
		},
		getValue(payment) {
			if (payment.paymentMethod == PaymentMethod.PROMOCODE)
				return payment.code;
			else if (payment.paymentMethod == PaymentMethod.CUSTOM) return "";
			else return payment.price + " " + payment.currency.toUpperCase();
		},
		getCategoryName(paymentOption) {
			if (paymentOption.categoryId == null) return "";

			let category = this.getCategory(
				this.$store.getters["directory/getCategories"],
				paymentOption.categoryId
			);

			return category.name;
		},
		initStripePortal() {
			this.loading = true;

			this.$store
				.dispatch("payments/createStripePortalSession")
				.then((url) => {
					window.open(url, "_self");
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
</style>
<template>
    <div id="search-bar">
        <v-menu
            offset-y
            transition="slide-y-transition"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="ml-2 d-sm-none"
                    v-bind="attrs"
                    v-on="on"
                    :color="headerSettings.contentColor"
                >
                    mdi-magnify
                </v-icon>
            </template>

            <v-list width="200">
                <v-list-item class="small pl-2 pr-2" dense>
                    <v-text-field
                        v-model="search"
                        class="search"
                        prepend-inner-icon="mdi-magnify"
                        background-color="white"
                        color="black"
                        outlined
                        rounded
                        dense
                        clearable
                        hide-details
                        @keyup.enter="startSearch"
                        @click:clear="clearSearch"
                    ></v-text-field>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-text-field
            v-model="search"
            class="d-none d-sm-block search mr-2"
            prepend-inner-icon="mdi-magnify"
            background-color="white"
            color="black"
            outlined
            rounded
            dense
            clearable
            hide-details
            @keyup.enter="startSearch"
            @click:clear="clearSearch"
        ></v-text-field>
    </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
    name: "SearchBar",
    data() {
        return {
            search: null,
            searched: false,
        };
    },
    computed: {
        headerSettings() {
            return this.$store.getters["directory/getDirectory"].settingsHeader;
        },
    },
    mounted() {
        EventBus.$on("remove-search", () => {
            this.clearSearch();
        });
    },
    methods: {
        startSearch() {
            this.searched = true;

            this.$store.commit("directory/SET_CURRENT_CATEGORY", null); // Clear category
            this.$store.commit("directory/SET_CURRENT_PAGE", 1); // Sets pagination back to first page

            EventBus.$emit("search", this.search);
        },
        clearSearch() {
            if (!this.searched) return;

            this.search = null;
            this.searched = false;

            this.$store.commit("directory/SET_CURRENT_PAGE", 1); // Sets pagination back to first page

            EventBus.$emit("search", null);
        },
    },
};
</script>

<style>
.search {
    width: 250px;
}

.search .v-text-field__slot input {
    color: black !important;
}

.search .v-icon {
    color: black !important;
}

.search .v-input__control .v-input__slot {
    min-height: 10px !important;
    display: flex !important;
    align-items: center !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.search .v-input__control .v-input__slot .v-input__prepend-inner,
.search .v-input__control .v-input__slot .v-input__append-inner {
    margin-top: 4px !important;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Directory from '@/views/Directory.vue'
import NotFound from '@/views/NotFound.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import PaymentSuccess from '@/views/PaymentSuccess.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/auth/:provider/callback',
		component: {
			template: '<div class="auth-component"></div>'
		}
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/not-found',
		name: 'NotFound',
		component: NotFound
	},
	{
		path: '/payment-success',
		name: 'PaymentSuccess',
		component: PaymentSuccess
	},
	{
		path: '/:directoryCode',
		name: 'Directory',
		component: Directory
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router

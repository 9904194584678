<template>
	<v-app :style="appStyle">
		<v-main id="main">
			<!-- Directory inside phone (screenshotlayer) -->
			<preview-phone v-if="screenshot">
				<router-view />
			</preview-phone>

			<router-view v-else />
		</v-main>
	</v-app>
</template>

<script>
import PreviewPhone from "@/components/PreviewPhone.vue";

export default {
	name: "App",
	components: {
		PreviewPhone,
	},
	created() {
		// Set device uuid
		this.$store.commit("auth/SET_DEVICE_UUID");

		// Check if phone preview (apiscreenshotlayer)
		const urlParams = new URLSearchParams(window.location.search);
		const screenshot = urlParams.get("screenshot");

		if (screenshot) this.$store.commit("directory/SET_SCREENSHOT", true);
	},
	mounted() {
		/**
		 * If directory is embedded in iframe (or WordPress) it will have issues with Stripe payment
		 * Stripe Checkout will redirect back to a success_url and cancel_url which are based on the directory url
		 * Because when using an iframe, the return urls need to be the parent url and not the actual directory url
		 * The parent is required to implement custom javascript using postMessage (https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
		 * This will send a message from parent to iframe with the parent url as iframes are not able to access parent information like url
		 */
		if (this.inIframe) {
			console.log("in Iframe");

			window.addEventListener("message", (e) => {
				if (!e.data || e.data === "" || e.data.type === "webpackOk") {
					return;
				}

				console.log(e.data);

				this.$store.commit("payments/STORE_PARENT_URL", e.data);
			});
		}
	},
	computed: {
		screenshot() {
			return this.$store.getters["directory/isScreenshot"];
		},
		settingsLayout() {
			let directory = this.$store.getters["directory/getDirectory"];

			if (directory) return directory.settingsLayout;

			return null;
		},
		appStyle() {
			if (this.$route.name != "Directory")
				return { backgroundColor: "#f1f1f1" };

			const layout = this.settingsLayout;

			if (layout == null) return {};

			let style = {
				fontFamily: layout.fontFamily + "!important",
			};

			// Change background when directory loaded
			if (layout.backgroundType == "color" || layout.background == null) {
				style.backgroundColor = layout.background;
			} else {
				style.backgroundImage = `url(${layout.background})`;
				style.backgroundRepeat = "repeat";
			}

			return style;
		},
		inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
	},
};
</script>

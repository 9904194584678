<template>
    <div>
        <div
            ref="addtohomescreen"
            :class="[
                'add-to-homescreen-container',
                opened
                    ? 'add-to-homescreen-visible'
                    : 'add-to-homescreen-hidden',
            ]"
        >
            <v-btn class="dialog__close" icon x-small @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <div class="flex">
                <div class="icon-container">
                    <span
                        v-if="iconImage"
                        class="icon"
                        :style="{
                            'background-image': 'url(' + iconImage + ')',
                        }"
                        ><template v-if="!iconImage">{{
                            firstCharTitle
                        }}</template>
                    </span>

                    <span
                        v-else
                        class="icon"
                        :style="{
                            backgroundColor: iconColor,
                            color: iconTextColor,
                        }"
                        >{{ firstCharTitle }}
                    </span>
                </div>

                <div class="col">
                    <span
                        class="app-title"
                        :style="{ color: getOpt('titleColor') }"
                        >{{
                            getOpt("title") ? getOpt("title") : appTitle
                        }}</span
                    ><br />
                    <span
                        class="app-content"
                        :style="{ color: getOpt('contentColor') }"
                        v-html="getOpt('content') || appUrl"
                    ></span>
                </div>
            </div>

            <div class="flex">
                <div class="col">
                    <div class="btn-container">
                        <button
                            @click="addToHomescreen"
                            class="add-button"
                            :style="{
                                color: getOpt('buttonTextColor'),
                                'background-color': getOpt('buttonColor'),
                            }"
                        >
                            {{ localizedString.addToHomescreen.toUpperCase() }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="dialog" width="600">
            <v-card>
                <v-btn
                    class="dialog__close"
                    icon
                    x-small
                    @click.native="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-card-title></v-card-title>

                <v-card-text class="text-center">
                    <div v-if="compatible">
                        <div
                            class="text-subtitle-2 mb-3"
                            v-html="
                                parseMessage(localizedString.addMessages.ios1)
                            "
                        />
                        <div
                            class="text-subtitle-2"
                            v-html="
                                parseMessage(localizedString.addMessages.ios2)
                            "
                        />
                    </div>

                    <div
                        v-else
                        class="text-subtitle-2"
                        v-html="localizedString.notCompatible"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import uaParser from "ua-parser-js";
import appLang from "@/locales/homescreen.js";

export default {
    name: "addToHomescreen",
    props: {
        title: {
            type: String,
            default: "",
        },
        content: {
            type: String,
        },
        titleColor: {
            type: String,
            default: "#000",
        },
        contentColor: {
            type: String,
            default: "#000",
        },
        iconColor: {
            type: String,
            default: "#000",
        },
        iconTextColor: {
            type: String,
            default: "#fff",
        },
        buttonColor: {
            type: String,
            default: "#118DEF",
        },
        buttonTextColor: {
            type: String,
            default: "#fff",
        },
        background: {
            type: String,
            default: "#fff",
        },
        lang: {
            type: String,
            default: "en_GB",
            //default: navigator.language || navigator.userLanguage,
        },
        expires: {
            type: Number,
            default: 7,
        },
    },
    data() {
        return {
            opened: false,
            dialog: false,
            compatible: true,
        };
    },
    computed: {
        layout() {
            return this.$store.getters["directory/getDirectory"].settingsLayout;
        },
        options() {
            return this.$root.$data;
        },
        appTitle() {
            return document.title;
        },
        appUrl() {
            return window.location.href;
        },
        iconImage() {
            const homescreen =
                this.layout.homescreen_icon ?? this.layout.favicon;

            return homescreen ?? null;
        },
        firstCharTitle() {
            return this.appTitle.substring(0, 1);
        },
        localizedString() {
            if (this.getOpt("lang") && appLang[this.lang]) {
                return appLang[this.getOpt("lang")];
            } else {
                return appLang.en_GB;
            }
        },
        isStandalone() {
            const webappsProtocols = ["file:", "cordova:", "capacitor:"];
            return (
                window.matchMedia("(display-mode: standalone)").matches ||
                (window.location &&
                    window.location.protocol &&
                    webappsProtocols.indexOf(window.location.protocol) !== -1)
            );
        },
    },
    methods: {
        setCookie() {
            // let exdate = new Date();
            // exdate.setDate(exdate.getDate() + this.expires);

            Cookies.set("addToHomescreenCalled", true);
        },
        getOpt(option) {
            return this.options[option] ? this.options[option] : this[option];
        },
        close() {
            this.setCookie();
            this.opened = false;
        },
        closeModal() {
            document.getElementById("IOSmodal").style.display = "none";
        },
        parseMessage(message) {
            message = message.replace(
                "[SHARE_ICON]",
                '<img src="' +
                    require(`@/assets/images/shareios.svg`) +
                    '" alt="share IOS" style="width:20px;"/>'
            );

            message = message.replace(
                "[HOMESCREEN_ICON]",
                '<img src="' +
                    require(`@/assets/images/addios.svg`) +
                    '" alt="add IOS"  style="width:20px;vertical-align:middle;"/>'
            );

            return message;
        },
        addToHomescreen() {
            const parsedUa = uaParser(window.navigator);

            if (parsedUa.os.name === "iOS") {
                this.compatible = parsedUa.browser.name.includes("Safari");

                // Open IOS modal only on IOS device
                this.dialog = true;
            } else if (parsedUa.os.name === "Android") {
                alert(this.localizedString.addMessages.android);
            } else if (
                parsedUa.os.name === "Windows" &&
                (parsedUa.browser.name === "Chrome" ||
                    parsedUa.browser.name === "Edge")
            ) {
                alert(this.localizedString.addMessages.windows.chrome);
            } else if (
                parsedUa.os.name === "Windows" &&
                parsedUa.browser.name === "Firefox"
            ) {
                alert(this.localizedString.addMessages.windows.firefox);
            } else if (parsedUa.os.name === "Mac OS") {
                const isTouchDevice =
                    "ontouchstart" in window ||
                    navigator.maxTouchPoints > 0 ||
                    navigator.msMaxTouchPoints > 0;

                if (isTouchDevice) {
                    //Open IOS modal only on IPad device
                    document.getElementById("IOSmodal").style.display = "block";
                } else {
                    if (parsedUa.browser.name === "Firefox") {
                        alert(this.localizedString.addMessages.macos.firefox);
                    } else if (parsedUa.browser.name === "Chrome") {
                        alert(this.localizedString.addMessages.macos.chrome);
                    } else if (parsedUa.browser.name === "Safari") {
                        alert(this.localizedString.addMessages.macos.safari);
                    }
                }
            } else {
                alert(this.localizedString.addMessages.others);
            }
            this.opened = false;
        },
    },
    created() {
        const parsedUa = uaParser(window.navigator);

        // Only show for iOS
        if (parsedUa.os.name !== "iOS") return;

        const getHomescreenCalledCookie = Cookies.get("addToHomescreenCalled");
        if (!this.isStandalone && !getHomescreenCalledCookie) {
            this.opened = true;
            this.setCookie();
        }
    },
};
</script>

<style scoped>
.add-to-homescreen-container {
    z-index: 10000;
    border-top: 1px solid #e0e0e0;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
    width: 100%;
    box-sizing: border-box;
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px 16px 6px 16px;
    align-items: center;
    transition: all 0.5s;
}

.add-to-homescreen-container.add-to-homescreen-visible {
    transform: translateY(0);
}
.add-to-homescreen-container.add-to-homescreen-hidden {
    transform: translateY(100%);
}

.close_btn {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    border: 0;
    /*background: url("assets/x.svg");*/
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1;
}

.icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-container .icon {
    width: 50px;
    height: 50px;
    display: block;
    line-height: 50px;
    text-align: center;
    border-radius: 30px;
    font-size: 1.3rem;
    margin-right: 15px;
    margin-top: 10px;
    text-transform: uppercase;
}

.app-title {
    font-size: 1.3rem;
    display: inline-block;
}
.app-content {
    font-size: 0.8rem;
    display: inline-block;
}

.btn-container {
    float: right;
}

.add-button,
.add-button:hover,
.add-button:visited {
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1rem;
    border-radius: 3px;
    padding: 5px 15px 5px 15px;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* IOS modal */
.modal {
    display: none;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    margin: 50% auto;
    border: 1px solid #27e9b8;
    width: 80%;
}

.modal-content ul {
    padding: 0;
    padding-left: 15px;
    text-align: left;
    list-style-type: none;
}

.modal-content .closeModal {
    color: #27e9b8;
    background-color: white;
    border: solid 0.1rem #27e9b8;
    border-radius: 0.3rem;
    font-size: 1rem;
    margin-bottom: 14px;
}
</style>
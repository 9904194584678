export default {
	methods: {
		/**
		 * 
		 * @param {Array} campaigns 
		 * @param {Array} categories 
		 * @param {String|null} search 
		 * @param {Object|null} category 
		 * @returns 
		 */
		filter(campaigns, categories, search, category) {
			campaigns = this.filterSearch(campaigns, search);
			campaigns = this.filterCategories(campaigns, category);

			return campaigns;
		},
		filterSearch(campaigns, search) {
			if (search == null) return campaigns;

			search = search.toLowerCase();

			let tmp = [];
			for (let i = 0; i < campaigns.length; ++i) {
				let campaign = campaigns[i].campaign;

				if (campaign == null) continue;

				let name = campaign.name ? campaign.name.toLowerCase() : "";
				let issuer = campaign.issuer ? campaign.issuer.toLowerCase() : "";
				let title = campaign.title ? campaign.title.toLowerCase() : "";
				let subtitle = campaign.subtitle ? campaign.subtitle.toLowerCase() : "";
				let description = campaign.description ? campaign.description.toLowerCase() : "";

				if (
					name.includes(search) ||
					title.includes(search) ||
					subtitle.includes(search) ||
					description.includes(search) ||
					issuer.includes(search)
				)
					tmp.push(campaigns[i]);
			}

			return tmp;
		},
		filterCategories(campaigns, category) {
			if (category == null) return campaigns;

			let categories = [category.id];
			categories.push(...this.getChildCategoryIds(category));

			return campaigns.filter((campaign) => categories.includes(campaign.categoryId));
		},
	}
}
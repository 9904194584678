<template>
    <div id="social-login" class="mt-2">
        <v-alert v-if="error" type="error" text dense>
            <label class="body-2">{{ error }}</label>
        </v-alert>

        <!-- Facebook -->
        <button
            v-if="settingsAuthentication.facebook"
            class="btn-social btn-fb mb-3"
            @click="useAuthProvider('facebook', Facebook)"
        >
            <div class="btn-content">
                <div class="logo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        version="1"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z"
                        />
                        <path
                            fill="#4267b2"
                            d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"
                        />
                    </svg>
                </div>
                <p class="mb-0">Continue with Facebook</p>
            </div>
        </button>

        <!-- Google	-->
        <button
            v-if="settingsAuthentication.google"
            class="btn-social btn-google mb-3"
            @click="useAuthProvider('google', Google)"
        >
            <div class="btn-content">
                <div class="logo">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 48 48"
                    >
                        <defs>
                            <path
                                id="a"
                                d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                            />
                        </defs>
                        <clipPath id="b">
                            <use xlink:href="#a" overflow="visible" />
                        </clipPath>
                        <path
                            clip-path="url(#b)"
                            fill="#FBBC05"
                            d="M0 37V11l17 13z"
                        />
                        <path
                            clip-path="url(#b)"
                            fill="#EA4335"
                            d="M0 11l17 13 7-6.1L48 14V0H0z"
                        />
                        <path
                            clip-path="url(#b)"
                            fill="#34A853"
                            d="M0 37l30-23 7.9 1L48 0v48H0z"
                        />
                        <path
                            clip-path="url(#b)"
                            fill="#4285F4"
                            d="M48 48L17 24l-4-3 35-10z"
                        />
                    </svg>
                </div>
                <p class="mb-0">Continue with Google</p>
            </div>
        </button>

        <!-- Twitter -->
        <button
            v-if="allowTwitter"
            class="btn-social btn-twitter mb-3"
            @click="useAuthProvider('twitter', Twitter)"
        >
            <div class="btn-content">
                <div class="logo">
                    <svg
                        viewBox="328 355 335 276"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="
								M 630, 425
								A 195, 195 0 0 1 331, 600
								A 142, 142 0 0 0 428, 570
								A  70,  70 0 0 1 370, 523
								A  70,  70 0 0 0 401, 521
								A  70,  70 0 0 1 344, 455
								A  70,  70 0 0 0 372, 460
								A  70,  70 0 0 1 354, 370
								A 195, 195 0 0 0 495, 442
								A  67,  67 0 0 1 611, 380
								A 117, 117 0 0 0 654, 363
								A  65,  65 0 0 1 623, 401
								A 117, 117 0 0 0 662, 390
								A  65,  65 0 0 1 630, 425
								Z"
                            style="fill: #ffffff"
                        />
                    </svg>
                </div>
                <p class="mb-0">Continue with Twitter</p>
            </div>
        </button>

        <div class="separator mb-4 mt-3 text--primary">
            {{ $t("authentication.sign-in-email") }}
        </div>

        <logging-in ref="loggingIn" />
    </div>
</template>

<script>
import { Providers } from "universal-social-auth";
import { Facebook, Google, Twitter } from "universal-social-auth";

import LoggingIn from "@/components/authentication/LoggingIn.vue";

export default {
    name: "SocialLogin",
    data() {
        return {
            allowTwitter: false, // Todo
            Facebook,
            Google,
            Twitter,

            responseData: {},
            hash: "",
            data: {},
            error: null,
        };
    },
    components: {
        LoggingIn,
    },
    computed: {
        directory() {
            return this.$store.getters["directory/getDirectory"];
        },
        settingsAuthentication() {
            return this.$store.getters["directory/getDirectory"]
                .settingsAuthentication;
        },
    },
    methods: {
        useAuthProvider(provider, proData) {
            let pro = proData;
            let ProData = pro || Providers[provider];

            ProData.redirectUri =
                window.location.origin + `/auth/${provider}/callback`;

            this.$Oauth
                .authenticate(provider, ProData)
                .then((response) => {
                    const resp = response;

                    if (resp.code) {
                        this.$refs.loggingIn.open(provider);

                        this.responseData.code = resp.code;
                        this.responseData.provider = provider;
                        this.useSocialLogin();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        useSocialLogin() {
            this.error = null;

            // otp from input Otp form
            // hash user data in your backend with Cache or save to database
            const pdata = {
                code: this.responseData.code,
                otp: this.data.tok,
                hash: this.hash,
            };

            this.$store
                .dispatch("auth/loginSocial", {
                    provider: this.responseData.provider,
                    data: pdata,
                })
                .then(() => {
                    location.reload();
                })
                .catch((error) => {
                    this.error = error.message;
                    this.$refs.loggingIn.close();
                });
        },
    },
};
</script>

<style scoped>
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #989898;
}

.separator:not(:empty)::before {
    margin-right: 1em;
}

.separator:not(:empty)::after {
    margin-left: 1em;
}

.btn-social {
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    text-decoration: none;
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);*/
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}
.btn-social .btn-content {
    display: flex;
    align-items: center;
    height: 44px;
}
.btn-social .btn-content .logo {
    padding: 10px;
    padding-left: 15px;
    height: inherit;
}
.btn-social .btn-content svg {
    width: 24px;
    height: 24px;
}
.btn-social .btn-content p {
    width: 100%;
    font-weight: 500;
    margin-right: 32px;
}

.btn-google {
    background: #fff;
    border: 1px solid #757575;
}
.btn-google .btn-content p {
    color: black;
}

.btn-fb {
    padding-top: 1.5px;
    background: #4267b2;
    background-color: #3b5998;
}
.btn-fb .btn-content p {
    color: white;
}

.btn-twitter {
    background: #1bb2e9;
}
.btn-twitter .btn-content p {
    color: #fff;
}
</style>
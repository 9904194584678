import Vue from 'vue'
import Vuex from 'vuex'

import authModule from "./auth.js";
import userModule from "./user.js";
import paymentsModule from "./payments.js";
import directoryModule from "./directory.js";
import cardsModule from "./cards.js";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth: authModule,
		user: userModule,
		payments: paymentsModule,
		directory: directoryModule,
		cards: cardsModule
	},
})

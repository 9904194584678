<template>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title></v-card-title>

            <v-card-text>
                <div v-html="content" class="ql-editor"></div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import "@/assets/css/quill.core.css";
import "@/assets/css/quill.snow.css";
import "@/assets/css/quill.bubble.css";

import { EventBus } from "@/event-bus.js";

export default {
    name: "TextDialog",
    data() {
        return {
            dialog: false,
            content: null,
        };
    },
    mounted() {
        EventBus.$on("open-text-dialog", (content) => {
            this.content = content;
            this.dialog = true;
        });
    },
};
</script>

<style scoped>
.ql-editor {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
}
</style>
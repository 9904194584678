<template>
    <v-skeleton-loader
        elevation="2"
        type="table-heading, list-item-two-line"
    ></v-skeleton-loader>
</template>

<script>
export default {
    name: "SkeletonCardList",
};
</script>
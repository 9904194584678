import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { toSnakeCase } from "../namingConversion.js";

Vue.use(Vuex);

const authModule = {
	namespaced: true,
	state: {
		authenticated: false,
		accessToken: localStorage.getItem('accessToken') || null,
		deviceUuid: localStorage.getItem('deviceUuid') || null,
	},
	mutations: {
		SET_ACCESS_TOKEN(state, value) {
			state.accessToken = value;

			localStorage.setItem('accessToken', value);

			axios.defaults.headers.common['Authorization'] = "Bearer " + value;
		},

		// Device UUID was originally set by 'device-uuid' package
		// It seems like this device uuid was not 100% unique and it was possible to get identical uuids for different devices
		// Changed to uuidv4() at 08/08/2022
		SET_DEVICE_UUID(state) {
			if (!state.deviceUuid) {
				state.deviceUuid = uuidv4();

				localStorage.setItem('deviceUuid', state.deviceUuid);
			}
		},

		SET_AUTHENTICATED(state, value) {
			state.authenticated = value;

			if (!state.authenticated) {
				axios.defaults.headers.common['Authorization'] = null;
				state.accessToken = null;
				state.authenticated = false;

				localStorage.removeItem('accessToken');
			}
		},
	},
	actions: {
		register(context, data) {
			// Copy data to make sure component data is unchanged
			data = toSnakeCase(Object.assign({}, data));

			return new Promise((resolve, reject) => {
				axios.post("/auth/register", data).then((resp) => {
					resolve(resp);
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},
		login({ commit, rootGetters }, credentials) {
			return new Promise((resolve, reject) => {
				credentials.id = rootGetters["directory/getId"];

				axios.post("/auth/login", credentials)
					.then((resp) => {
						commit("SET_ACCESS_TOKEN", resp.data.access_token);

						resolve();
					}).catch((error) => {
						reject(error.response.data);
					});
			});
		},
		loginToken({ commit, rootGetters }, token) {
			return new Promise((resolve, reject) => {
				const data = {
					id: rootGetters["directory/getId"],
					token: token
				};

				axios.post("/auth/login/token", data)
					.then((resp) => {
						commit("SET_ACCESS_TOKEN", resp.data.access_token);

						resolve();
					}).catch((error) => {
						reject(error.response.data);
					});
			});
		},
		loginSocial({ commit, rootGetters }, { provider, data }) {
			return new Promise((resolve, reject) => {
				data['id'] = rootGetters["directory/getId"];

				axios.post("/auth/" + provider, data)
					.then((resp) => {
						commit("SET_ACCESS_TOKEN", resp.data.access_token);

						resolve();
					}).catch((error) => {
						reject(error.response.data);
					});
			});
		},
		logout({ commit }) {
			return new Promise((resolve) => {
				axios.post("/auth/logout").finally(() => {
					commit("SET_AUTHENTICATED", false);
					commit("user/SET_USER", null, { root: true });

					resolve();
				});
			});
		},
		forgotPassword({ rootGetters }, data) {
			data['id'] = rootGetters["directory/getId"];

			return new Promise((resolve, reject) => {
				axios.post("/auth/password/forgot", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},
		resetPassword(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/auth/password/reset", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response.data);
				});
			});
		},
	},
	getters: {
		authenticated(state) {
			return state.authenticated;
		},

		getAccessToken(state) {
			return state.accessToken;
		},

		hasAccessToken(state) {
			return state.accessToken != null;
		},

		getDeviceUuid(state) {
			return state.deviceUuid;
		}
	},
};

export default authModule;

<template>
    <v-container fill-height>
        <v-row>
            <v-col cols="12" align="center">
                <v-card class="pl-2 pr-2 text-left" width="420">
                    <v-card-title class="justify-center">
                        Reset your password
                    </v-card-title>

                    <v-card-text>
                        <v-alert
                            v-if="messageSuccess"
                            type="success"
                            text
                            dense
                        >
                            <label class="body-2">
                                {{ messageSuccess }}
                            </label>
                        </v-alert>

                        <form
                            v-else
                            id="reset-password"
                            v-on:submit.prevent="resetPassword"
                        >
                            <default-input
                                v-model="password"
                                type="password"
                                label="Password"
                                required
                                hide-details
                                :error="passwordError"
                            />

                            <default-input
                                v-model="passwordConfirmation"
                                type="password"
                                label="Confirm password"
                                required
                                hide-details
                                :error="passwordError"
                            />

                            <!-- Message success -->
                            <p v-if="messageSuccess" class="green--text mb-0">
                                {{ messageSuccess }}
                            </p>

                            <!-- Token error -->
                            <p v-if="tokenError" class="error--text mb-0">
                                {{ tokenError }}
                            </p>

                            <v-btn
                                type="submit"
                                class="white--text text-none"
                                color="primary"
                                large
                                block
                                depressed
                                :loading="requesting"
                            >
                                Reset password
                            </v-btn>
                        </form>

                        <!-- Back to directory -->
                        <div class="text-center mt-5">
                            <a :href="'/' + code"> Back to sign in </a>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DefaultInput from "@/components/input/DefaultInput.vue";

export default {
    name: "ResetPassword",
    components: {
        DefaultInput,
    },
    data() {
        return {
            token: null,
            email: null,
            password: null,
            passwordConfirmation: null,
            code: null,
            messageSuccess: null,
            passwordError: null,
            tokenError: null,
            requesting: false,
        };
    },
    mounted() {
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;
        this.code = this.$route.query.code;

        if (!this.token || !this.email) this.$router.push({ name: "NotFound" });
    },
    methods: {
        resetPassword() {
            if (!this.passwordValidation()) return;

            this.requesting = true;

            this.$store
                .dispatch("auth/resetPassword", {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                })
                .then(() => {
                    this.messageSuccess =
                        'Your password has been reset successfully. You\'ll automatically be redirected to the directory in a few seconds or click "Back to directory" below.';

                    setTimeout(
                        () => (window.location = "/" + this.code + "/"),
                        4000
                    );
                })
                .catch((error) => {
                    if (error.errors) {
                        let errors = error.errors;

                        for (const value of Object.values(errors)) {
                            this.passwordError = value[0];
                        }
                    } else {
                        this.tokenError =
                            "The provided token is invalid or outdated";
                    }
                })
                .finally(() => {
                    this.requesting = false;
                });
        },
        passwordValidation() {
            this.passwordError = null;

            if (this.password != this.passwordConfirmation)
                this.passwordError =
                    "The password confirmation does not match.";

            if (this.password.length < 8)
                this.passwordError =
                    "The password must be at least 8 characters.";

            return this.passwordError == null;
        },
    },
};
</script>

<template>
	<v-container fill-height>
		<v-card v-if="pending" width="400" class="mx-auto">
			<v-card-text class="text-center pt-6 pl-10 pr-10">
				<h2>Payment in progress</h2>

				<div class="subtitle-1 mt-2">
					Waiting for payment confirmation...
				</div>

				<v-progress-circular
					class="mt-6 mb-4"
					color="success"
					indeterminate
					size="54"
				></v-progress-circular>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import PaymentMethod from "../enums/PaymentMethod";
export default {
	name: "PaymentSuccess",
	data() {
		return {
			code: null,
			session_id: null,

			pending: false,
		};
	},
	mounted() {
		this.type = this.$route.query.type ?? PaymentMethod.STRIPE;
		this.code = this.$route.query.code;
		this.session_id = this.$route.query.session_id;

		this.retrievePaymentStatus();
	},
	methods: {
		retrievePaymentStatus() {
			let statusPromise = null;
			if (this.type === PaymentMethod.PAYSTACK) {
				statusPromise = this.$store.dispatch(
					"payments/retrievePaystackPaymentStatus",
					this.session_id
				);
			} else {
				// Stripe was the original payment integration so everything was built under the assumption Stripe was the integration.
				// As such, whenever we don't explicitly state the integration, we have to assume it's Stripe
				statusPromise = this.$store.dispatch(
					"payments/retrieveStripePaymentStatus",
					this.session_id
				);
			}

			statusPromise
				.then((resp) => {
					if (resp.confirmed) {
						// Payment confirmed --> redirect back to directory
						this.$router.push("/" + this.code + "/");
					} else {
						// Only show waiting screen when initial payment status check is still pending
						this.pending = true;

						// Payment not confirmed yet --> wait for payment success
						window.setTimeout(() => {
							this.retrievePaymentStatus();
						}, 2500);
					}
				})
				.catch(() => {
					this.$router.push(
						"/" + this.code + "/?event=payment_failed"
					);
				});
		},
	},
};
</script>

<style>
</style>
<template>
    <div class="default-input mb-4">
        <label class="input__label">
            {{ label }}
            <v-tooltip v-if="tooltip" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        class="more-info-btn"
                        size="16"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'question']"
                            color="white"
                            size="xs"
                        />
                    </v-avatar>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </label>

        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <input
                    v-model="input"
                    class="form-input form-input__regular"
                    :label="inputLabel"
                    :disabled="inputDisabled"
                    :required="required"
                    v-bind="attrs"
                    v-on="on"
                    @keydown="readonly"
                />
            </template>

            <v-date-picker
                v-model="input"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="save"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "DefaultDatePicker",
    props: ["value", "label", "placeholder", "disabled", "tooltip", "required"],
    data() {
        return {
            input: this.value,
            menu: false,
            activePicker: null,
        };
    },
    computed: {
        inputLabel() {
            return this.placeholder ?? null;
        },
        inputDisabled() {
            return this.disabled ?? false;
        },
    },
    methods: {
        save(date) {
            this.$refs.menu.save(date);
        },
        readonly($event) {
            $event.preventDefault();
        },
    },
    watch: {
        input: function () {
            this.$emit("input", this.input);
        },
        value: function () {
            this.input = this.value;
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
};
</script>

<style scoped>
.form-input {
    width: 100%;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    color: black;
}

.form-input__regular {
    padding: 8px;
}
</style>
<template>
	<v-dialog
		v-model="dialog"
		content-class="overflow-x-hidden"
		width="420"
		persistent
	>
		<v-card id="auth-card">
			<v-card-title class="justify-center">
				{{ modalTitle }}

				<v-btn
					class="dialog__close"
					icon
					x-small
					@click.native="dialog = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text>
				<login-screen
					v-if="active == pages.LOGIN"
					@openRegister="active = pages.REGISTER"
					@openForgotPassword="active = pages.FORGET_PASSWORD"
				/>

				<register-screen
					v-else-if="active == pages.REGISTER"
					@openLogin="active = pages.LOGIN"
				/>

				<forgot-password-screen
					v-else
					@openLogin="active = pages.LOGIN"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import LoginScreen from "@/components/authentication/LoginScreen.vue";
import RegisterScreen from "@/components/authentication/RegisterScreen.vue";
import ForgotPasswordScreen from "@/components/authentication/ForgotPasswordScreen.vue";

const Pages = Object.freeze({
	LOGIN: "login",
	REGISTER: "register",
	FORGET_PASSWORD: "forgot-password",
});

export default {
	name: "AuthenticationDialog",
	components: {
		LoginScreen,
		RegisterScreen,
		ForgotPasswordScreen,
	},
	data() {
		return {
			dialog: false,
			active: Pages.LOGIN,

			// Use in template
			pages: Pages,
		};
	},
	computed: {
		modalTitle() {
			if (this.active == Pages.LOGIN)
				return this.$t("authentication.sign-in");
			else if (this.active == Pages.REGISTER)
				return this.$t("authentication.sign-up");
			else if (this.active == Pages.FORGET_PASSWORD)
				return this.$t("authentication.request-new-password");

			return null;
		},
	},
	mounted() {
		EventBus.$on("open-authentication-dialog", () => (this.dialog = true));

		let directory = this.$store.getters["directory/getDirectory"];

		// Check if authentication dialog needs to open on initial page load
		if (directory.settingsAuthentication.showOnLoad) this.dialog = true;
	},
	watch: {
		dialog() {
			if (this.dialog == false) {
				let self = this;

				// Set small delay so the jump to login is not noticable
				setTimeout(function () {
					self.active = Pages.LOGIN;
				}, 500);
			}
		},
	},
};
</script>

<style scoped>
/**
 * vue-tel-input refuses to go on top of dialog
 * - z-index has no effect
 * - Removing overflow-y from v-dialog solves problem but does not allow to scroll vertically on smaller screens (no solution)
 * Using these max sizes + overflow-x hidden it forces the dropdown to always fit the dialog
 */
.overflow-x-hidden {
	overflow-x: hidden;
}
</style>

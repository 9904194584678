<template>
    <div class="default-input mb-4">
        <label class="input__label">
            {{ label }}
            <v-tooltip v-if="tooltip" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        class="more-info-btn"
                        size="16"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'question']"
                            color="white"
                            size="xs"
                        />
                    </v-avatar>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </label>

        <vue-tel-input
            v-model="input"
            @input="(text, object) => phoneChanged(object)"
            validCharactersOnly
            :disabled="inputDisabled"
            :inputOptions="{ required: required }"
        />

        <p class="error--text mb-0" v-if="error">
            {{ error }}
        </p>
    </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";

export default {
    name: "DefaultPhoneInput",
    props: [
        "value",
        "label",
        "placeholder",
        "type",
        "disabled",
        "tooltip",
        "required",
        "error",
    ],
    components: {
        VueTelInput,
    },
    data() {
        return {
            input: this.value,
        };
    },
    computed: {
        inputLabel() {
            return this.placeholder ?? null;
        },
        inputDisabled() {
            return this.disabled ?? false;
        },
    },
    methods: {
        phoneChanged(object) {
            this.input = object.number;

            this.$emit("object", object);
        },
    },
    watch: {
        input: function () {
            /**
             *
             */
            this.$emit("input", this.input);
        },
        value: function () {
            this.input = this.value;
        },
    },
};
</script>

<style>
.vue-tel-input {
    border: 1px solid rgba(0, 0, 0, 0.4) !important;
    border-radius: 4px !important;
}

.vue-tel-input:focus,
.vue-tel-input:focus-within {
    outline: none !important;
    box-shadow: none !important;
}

/**
 * vue-tel-input refuses to go on top of dialog
 * - z-index has no effect
 * - Removing overflow-y from v-dialog solves problem but does not allow to scroll vertically on smaller screens (no solution)
 * Using these max sizes it forces the dropdown to always fit the dialog
 */
.vti__dropdown-list {
    max-height: 120px !important;
    max-width: 320px !important;
}
</style>


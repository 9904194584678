import { EventBus } from "@/event-bus.js";
import store from '@/store/index';

import paymentHelpers from "@/mixins/paymentHelpers.js";
import categoryHelpers from "@/mixins/categoryHelpers.js";

function redeem(card) {
	// Check if any card already in loading state
	if (store.getters["cards/loading"]) return;

	// Set global state to loading
	setLoading(true);

	// Check if user is authenticated
	if (!isAuthenticated()) {
		// If not logged in, open authentication dialog
		EventBus.$emit("open-authentication-dialog");

		return setLoading(false);
	}

	let paymentSettings = store.getters["directory/getDirectory"].settingsPayment;

	// Check if directory requires payment
	if (paymentSettings.requirePayment) {
		let paymentOption = null;

		// Always check directory payments, also if payment restriction is set to category!
		// Promo codes work at directory level and get priority over category level
		if (paymentHelpers.methods.directoryPaymentSuccess()) return openCard(card);

		if (paymentSettings.paymentRestriction === "directory") {
			paymentOption = paymentSettings.paymentDirectory;
		} else {
			// Get all parent categories from card category
			// You can only set category payments on 1st level categories
			let parentCategories = categoryHelpers.methods.getRecursiveCategories(
				store.getters["directory/getCategories"],
				card.categoryId
			);

			if (!paymentHelpers.methods.categoryPaymentSuccess(parentCategories)) {
				paymentOption = getCategoryPaymentOption(card);
			}
		}

		if (paymentOption) {
			// Payment required --> open payment dialog
			EventBus.$emit("open-payment-dialog", paymentOption);

			return setLoading(false);
		}
	}

	openCard(card);
}

function openCard(card) {
	// Store current category in localStorage to open by default when returning to directory
	const currentCategory = store.getters["directory/getCurrentCategory"];

	if (currentCategory)
		localStorage.setItem("current-category", currentCategory);

	const cardSettings = store.getters["directory/getDirectory"].settingsCard;

	// Only store current page when ordering is not random
	// If random, it will just give different cards when you load the current page
	if (cardSettings.order !== "random") {
		const currentPage = store.getters["directory/getCurrentPage"];

		if (currentPage)
			localStorage.setItem("current-page", currentPage);
	}

	if (card.redeemUrl) {
		setLoading(false);

		window.open(card.redeemUrl, "_self");
	} else {
		store.dispatch("cards/redeem", card.id)
			.then(() => {
				window.open(card.redeemUrl, "_self");
			})
			.finally(() => {
				setLoading(false);
			});
	}
}

function getCategoryPaymentOption(card) {
	if (card.categoryId == null) return null;

	let categoryPayments = store.getters["directory/getDirectory"].settingsPayment.paymentCategories;

	let parentCategories = categoryHelpers.methods.getRecursiveCategories(
		store.getters["directory/getCategories"],
		card.categoryId
	);

	let paymentOption = categoryPayments.find((obj) => {
		return parentCategories.includes(obj.categoryId);
	});

	return paymentOption;
}

function isAuthenticated() {
	if (!store.getters["directory/getDirectory"].settingsAuthentication.requireAuthentication) return true;

	return store.getters["auth/authenticated"];
}

function setLoading(loading) {
	store.dispatch("cards/setLoading", loading);

	// Make sure all cards reset loading state
	if (loading === false) EventBus.$emit("reset-loading");
}

export { redeem }
<template>
    <div class="default-input mb-4">
        <label class="input__label">
            {{ label }}
            <v-tooltip v-if="tooltip" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        class="more-info-btn"
                        size="16"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'question']"
                            color="white"
                            size="xs"
                        />
                    </v-avatar>
                </template>
                <span>{{ tooltip }}</span>
            </v-tooltip>
        </label>

        <input
            v-model="input"
            class="form-input form-input__regular"
            :class="{ 'input-error': error }"
            :label="inputLabel"
            :type="type"
            :disabled="inputDisabled"
            :required="required"
            :placeholder="placeholder"
        />

        <p class="error--text text-left mb-0" v-if="error">
            {{ error }}
        </p>
    </div>
</template>

<script>
export default {
    name: "DefaultInput",
    props: [
        "value",
        "label",
        "placeholder",
        "type",
        "disabled",
        "tooltip",
        "required",
        "error",
    ],
    data() {
        return {
            input: this.value,
        };
    },
    computed: {
        inputLabel() {
            return this.placeholder ?? null;
        },
        inputDisabled() {
            return this.disabled ?? false;
        },
    },
    watch: {
        input: function () {
            this.$emit("input", this.input);
        },
        value: function () {
            this.input = this.value;
        },
    },
};
</script>

<style scoped>
.form-input {
    width: 100%;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.form-input__regular {
    padding: 8px;
}
</style>

<template>
    <v-container fill-height>
        <v-card max-width="400" class="mx-auto">
            <v-card-text class="text-center">
                The directory you are trying to access does not exist.
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "NotFound",
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"min-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"dialog__close",attrs:{"icon":"","x-small":""},nativeOn:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{staticClass:"mb-4",attrs:{"color":_vm.paymentColor,"size":"132"}},[_vm._v(" "+_vm._s(_vm.paymentIcon)+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.user.payments,"hide-default-footer":"","dense":"","no-data-text":_vm.$t('payments.not-found')},scopedSlots:_vm._u([{key:"item.paidAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.paidAt))+" ")]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.expiresAt))+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCategoryName(item.paymentOption))+" ")]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentMethod(item.paymentMethod))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.receiptUrl)?_c('a',{attrs:{"href":item.receiptUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-receipt-text")])],1):_vm._e(),(item.invoiceUrl)?_c('a',{attrs:{"href":item.invoiceUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-receipt-text")])],1):_vm._e()]}}],null,true)}),(_vm.showBillingPortal)?_c('v-btn',{staticClass:"mt-3",style:(_vm.buttonStyleIgnoreWidth),attrs:{"loading":_vm.loading,"depressed":""},on:{"click":_vm.initStripePortal}},[_vm._v(" "+_vm._s(_vm.$t("payments.manage-payments"))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
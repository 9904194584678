export default {
	methods: {
		/**
		 * Get parent categories of categoryId
		 *
		 * @param {Array} categories
		 * @param {Number} category
		 * @returns {Array}
		 */
		getRecursiveCategories(categories, categoryId) {
			// Loop all level 1 categories
			for (let i = 0; i < categories.length; ++i) {
				// Get all children of level 1 categories
				let children = this.getChildCategoriesUntilId(
					categories[i],
					categoryId
				);

				// Check if category is inside children categories
				if (children && children.includes(categoryId)) return children;
			}

			return [];
		},

		/**
		 * Get child categories of category until id found
		 *
		 * @param {Object} category
		 * @param {Number} id
		 * @param {Array} children
		 * @returns {Array}
		 */
		getChildCategoriesUntilId(category, id, children = []) {
			if (category.id == id) return [category.id];

			// Check if category has children
			if (category.children && category.children.length) {
				children.push(category.id);

				let found = false;
				category.children.forEach((child) => {
					// Get children of child category
					const tmpChildren = this.getChildCategoriesUntilId(child, id);
					if (tmpChildren) {
						children = children.concat(tmpChildren);
						found = true;
					}
				});

				// Pop last category if id is not in children
				!found && children.pop();

				return children;
			}

			return null;
		},

		/**
		 * Find a category and all its parent categories
		 * 
		 * @param {Array} categories 
		 * @param {Number} searchedCategoryId 
		 * @returns {Array|null}
		 */
		getPathToCategory(categories, searchedCategoryId) {
			for (let i = 0; i < categories.length; i++) {
				const category = categories[i];
				if (category.id == searchedCategoryId) {
					// We just found our category
					return [category.id];
				} else {
					// Recursively look through the children of the category
					let search = this.getPathToCategory(category.children, searchedCategoryId);
					// When we find that the category we're looking for is a (deep) child of the current one we add this category (which is a parent) to the path
					if (search !== null) return [category.id, ...search];
				}
			}

			// None of the categories was the category we were looking for or is a parent of the category we're looking for
			return null;
		},

		/**
		 * Get all category ids any child categories
		 * @param {Object} category 
		 * @returns {Array}
		 */
		getChildCategoryIds(category, childCategoryIds = []) {
			if (category.children.length == 0) return childCategoryIds;

			for (let i = 0; i < category.children.length; ++i) {
				childCategoryIds.push(category.children[i].id);

				childCategoryIds = this.getChildCategoryIds(category.children[i], childCategoryIds);
			}

			return childCategoryIds;
		},

		/**
		 * Find a direct parent of a category
		 * 
		 * @param {Array} categories
		 * @param {Number} categoryId 
		 * @returns {Number|null}
		 */
		getParentCategoryId(categories, categoryId) {
			for (let i = 0; i < categories.length; i++) {
				const category = categories[i];
				let hasCategoryAsChild = category.children.find((child) => child.id === categoryId) !== undefined;

				if (hasCategoryAsChild) {
					// We just found our category's parent
					return category.id;
				} else {
					// Recursively check if any of the child categories is a direct parent of the category we're looking for
					let search = this.getParentCategoryId(category.children, categoryId);
					if (search !== null) return search;
				}
			}

			// None of the categories was a parent of the category we're looking for
			return null;
		},

		/**
		 * Get category by Id
		 *
		 * @param {Array} categories
		 * @param {Number} categoryId
		 */
		getCategory(categories, categoryId) {
			for (let i = 0; i < categories.length; ++i) {
				if (categories[i].id == categoryId) return categories[i];

				let category = this.getCategory(categories[i].children, categoryId);

				if (category) return category;
			}
		}
	}
}
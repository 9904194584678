<template>
	<v-dialog v-model="dialog" width="400" persistent>
		<v-card v-if="paymentOption" class="text-center">
			<v-btn
				class="dialog__close"
				icon
				x-small
				@click.native="dialog = false"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>

			<v-card-title class="justify-center">
				{{ dialogTitle }}
			</v-card-title>

			<v-card-text class="pb-4">
				<!-- Description -->
				<div class="mb-2" v-html="paymentOption.description" />

				<!-- Payment methods -->
				<v-radio-group v-model="selectedPaymentMethod" hide-details>
					<v-radio
						v-for="(paymentMethod, index) in paymentMethods"
						:key="index"
						class="payment-method"
						:style="methodSelected(paymentMethod.value)"
						:value="paymentMethod.value"
						:color="cardSettings.buttonBackgroundColor"
					>
						<template v-slot:label>
							<label class="payment-method__label">
								{{ paymentMethod.text }}
							</label>
						</template>
					</v-radio>
				</v-radio-group>

				<!-- Promo code input -->
				<default-input
					v-if="
						selectedPaymentMethod !== null &&
						selectedPaymentMethod.method === PaymentMethod.PROMOCODE
					"
					v-model="promoCode"
					placeholder="Code"
					:error="promoCodeError"
					:disabled="loading"
				/>

				<v-divider class="mt-2"></v-divider>

				<!-- Price -->
				<v-row>
					<v-col cols="4" class="text-left">
						<div class="text-h6">Total</div>
					</v-col>
					<v-col cols="8" class="text-right">
						<div class="text-h6">
							{{ paymentPrice + " " + paymentSettings.currency }}
							<label class="caption font-italic">
								{{ recurringPeriod }}
							</label>
						</div>
					</v-col>
				</v-row>

				<div v-if="error" class="float-left error--text">
					{{ error }}
				</div>

				<!-- Purchase -->
				<v-btn
					class="purchase"
					:style="buttonStyle"
					@click="submit"
					:loading="loading"
					:disabled="selectedPaymentMethod == null"
					depressed
					large
				>
					{{ purchaseButtonText }}
				</v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import categoryHelpers from "@/mixins/categoryHelpers.js";
import paymentHelpers from "@/mixins/paymentHelpers.js";
import layoutHelpers from "@/mixins/layoutHelpers.js";

import DefaultInput from "@/components/input/DefaultInput.vue";
import PaymentMethod from "../../enums/PaymentMethod.js";
import PaymentType from "../../enums/PaymentType.js";

export default {
	components: {
		DefaultInput,
	},
	data() {
		return {
			PaymentMethod,

			dialog: false,
			loading: false,

			paymentOption: null,
			selectedPaymentMethod: null,

			error: null,
			promoCode: null,
			promoCodeError: null,
		};
	},
	mixins: [categoryHelpers, paymentHelpers, layoutHelpers],
	mounted() {
		EventBus.$on("open-payment-dialog", (paymentOption) => {
			this.paymentOption = paymentOption;
			this.dialog = true;
		});

		if (
			this.paymentSettings.showOnLoad &&
			this.paymentSettings.paymentRestriction == "directory" &&
			!this.directoryPaymentSuccess()
		) {
			this.paymentOption = this.paymentSettings.paymentDirectory;
			this.dialog = true;
		}
	},
	computed: {
		paymentSettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsPayment;
		},
		paymentMethods() {
			let paymentMethods = [];
			if (this.paymentSettings.paymentStripe) {
				paymentMethods.push({
					text: this.$t("payments.method-stripe"),
					value: {
						method: PaymentMethod.STRIPE,
					},
				});
			}

			if (this.paymentSettings.paymentPaystack) {
				paymentMethods.push({
					text: this.$t("payments.method-paystack-card"),
					value: {
						method: PaymentMethod.PAYSTACK,
						channel: "card",
					},
				});
				paymentMethods.push({
					text: this.$t("payments.method-paystack-mobile"),
					value: {
						method: PaymentMethod.PAYSTACK,
						channel: "mobile_money",
					},
				});
			}

			if (this.paymentSettings.paymentPromocode) {
				paymentMethods.push({
					text: this.$t("payments.method-access-code"),
					value: {
						method: PaymentMethod.PROMOCODE,
					},
				});
			}

			return paymentMethods;
		},
		cardSettings() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		paymentPrice() {
			if (this.selectedPaymentMethod == PaymentMethod.PROMOCODE)
				return "0.00";

			return this.paymentOption.price;
		},
		recurringPeriod() {
			let paymentType = this.paymentSettings.paymentType;

			if (paymentType == PaymentType.WEEKLY) return "/week";
			else if (paymentType == PaymentType.MONTHLY) return "/month";
			else if (paymentType == PaymentType.QUARTERLY) return "/3 months";
			else if (paymentType == PaymentType.YEARLY) return "/year";

			return "";
		},
		dialogTitle() {
			if (this.paymentOption.categoryId == null)
				return this.$t("payments.payment");

			let category = this.getCategory(
				this.$store.getters["directory/getCategories"],
				this.paymentOption.categoryId
			);

			return category.name;
		},
		purchaseButtonText() {
			if (this.selectedPaymentMethod === null) {
				return this.$t("payments.payment-dialog.select-an-option");
			} else if (
				this.selectedPaymentMethod.method === PaymentMethod.PROMOCODE
			) {
				return this.$t("payments.payment-dialog.redeem");
			} else {
				return this.$t("payments.payment-dialog.go-to-checkout");
			}
		},
	},
	methods: {
		submit() {
			if (this.selectedPaymentMethod === null) return;
			this.loading = true;
			this.promoCodeError = null;

			switch (this.selectedPaymentMethod.method) {
				case PaymentMethod.STRIPE:
					this.createStripeSession();
					break;
				case PaymentMethod.PAYSTACK:
					this.createPaystackSession(
						this.selectedPaymentMethod.channel
					);
					break;
				case PaymentMethod.PROMOCODE:
					this.redeemPromoCode();
					break;
			}
		},
		createStripeSession() {
			this.$store
				.dispatch("payments/createStripeSession", this.paymentOption.id)
				.then((url) => {
					window.top.location.href = url;
				})
				.catch(() => {
					this.error = "Something went wrong...";
				})
				.finally(() => {
					this.loading = false;
				});
		},
		createPaystackSession(channel) {
			this.$store
				.dispatch("payments/createPaystackSession", {
					paymentOptionId: this.paymentOption.id,
					channel: channel,
				})
				.then((url) => {
					window.top.location.href = url;
				})
				.catch(() => {
					this.error = "Something went wrong...";
				})
				.finally(() => {
					this.loading = false;
				});
		},
		redeemPromoCode() {
			this.$store
				.dispatch("payments/redeemPromoCode", this.promoCode)
				.then(() => {
					const directory =
						this.$store.getters["directory/getDirectory"];

					window.location =
						directory.url + "?event=promocode_success";
				})
				.catch((error) => {
					if (error.response.status == 409) {
						this.promoCodeError = this.$t(
							"payments.invalid-access-code"
						);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		methodSelected(method) {
			if (method != this.selectedPaymentMethod) return {};

			let singleInset = `inset 0 0 0 2px ${this.cardSettings.buttonBackgroundColor}`;

			let inset = singleInset + "," + singleInset + "," + singleInset;

			return { boxShadow: inset };
		},
	},
};
</script>

<style scoped>
.payment-method {
	padding: 10px 15px 10px 12px;
	background-color: rgb(246, 249, 248);
	border-radius: 6px;
	margin-bottom: 10px;
	cursor: pointer;
}

.payment-method__label {
	font-weight: 600;
	margin-left: 12px;
	cursor: pointer;
}

.purchase {
	padding: 10px 15px 10px 15px !important;
	font-size: 16px;
}
</style>
<template>
	<div id="categories">
		<!-- Left categories -->
		<div
			v-if="categorySettings.leftCategories"
			id="categories-left"
			class="hidden-sm-and-down"
		>
			<v-treeview
				:active.sync="active"
				:items="categories"
				:open.sync="openCategories"
				:open-all="categorySettings.open"
				activatable
				transition
				expand-icon="mdi-chevron-down"
				:dense="categorySettings.dense"
				:color="categorySettings.leftActiveBackgroundColor"
				:style="treeviewStyle"
			>
				<template v-slot:label="{ item }">
					<div :style="leftLabelStyle">
						<label class="text-left">
							{{ item.name }}
						</label>
						<v-icon
							v-if="requiresPayment(item.id)"
							class="float-right mr-2"
							:style="iconColorStyle"
						>
							{{ paymentIcon(item.id) }}
						</v-icon>
					</div>
				</template>
			</v-treeview>
		</div>

		<!-- Categories top -->
		<div
			id="categories-top"
			:class="{ 'hidden-md-and-up': categorySettings.leftCategories }"
		>
			<button
				v-if="active.length"
				class="back-button mr-2"
				@click="backOutOfCategory"
				:style="topButtonStyle()"
			>
				<v-icon :style="iconStyle">mdi-arrow-left</v-icon>
			</button>

			<!-- Category buttons -->
			<button
				v-for="category in currentCategoryChildren"
				:key="category.id"
				class="category-button mr-2"
				@click="active = [category.id]"
				:style="topButtonStyle(category.id)"
			>
				{{ category.name }}
			</button>
		</div>
	</div>
</template>

<script>
import categoryHelpers from "@/mixins/categoryHelpers.js";
import paymentHelpers from "@/mixins/paymentHelpers.js";

export default {
	name: "Categories",
	data() {
		return {
			openCategories: [],
			active: [],
		};
	},
	mixins: [categoryHelpers, paymentHelpers],
	computed: {
		categorySettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsCategory;
		},
		paymentSettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsPayment;
		},
		categories() {
			return this.$store.getters["directory/getCategories"];
		},
		currentCategory() {
			return this.$store.getters["directory/getCurrentCategory"];
		},
		currentCategoryChildren() {
			if (this.currentCategory === null) {
				return this.categories;
			} else {
				return this.getCategory(this.categories, this.currentCategory)
					.children;
			}
		},
		treeviewStyle() {
			return {
				color: this.categorySettings.leftTextColor,
				backgroundColor: this.categorySettings.leftBackgroundColor,
			};
		},
		leftLabelStyle() {
			return {
				fontSize: this.categorySettings.leftFontSize + "px",
				fontWeight: this.categorySettingsleftFontWeight,
			};
		},
		iconStyle() {
			let fontSize = this.categorySettings.topFontSize + 4;

			return {
				color: this.categorySettings.topTextColor,
				fontSize: fontSize + "px",
			};
		},
		iconColorStyle() {
			return {
				color: this.categorySettings.topTextColor,
			};
		},
	},
	methods: {
		topButtonStyle(categoryId = null) {
			let style = {
				color: this.categorySettings.topTextColor,
				backgroundColor: this.categorySettings.topBackgroundColor,
				fontSize: this.categorySettings.topFontSize + "px",
				borderRadius: this.categorySettings.topBorderRadius + "px",
				borderWidth: this.categorySettings.topBorderThickness + "px",
				// borderColor: this.categorySettings.topBorderColor,
			};

			let active = this.isActiveCategory(categoryId);

			style.fontWeight = active
				? 700
				: this.categorySettings.topFontWeight;

			// Inset border to prevent layout push
			if (active)
				style.boxShadow = `inset 0 0 0 1px ${this.categorySettings.topBorderColor}`;

			return style;
		},
		isActiveCategory(categoryId) {
			if (categoryId == null) return false;

			return this.active.length && this.active[0] == categoryId;
		},
		requiresPayment(categoryId) {
			if (
				!this.paymentSettings.requirePayment ||
				this.paymentSettings.paymentRestriction != "category"
			)
				return false;

			let categoryPayments = this.paymentSettings.paymentCategories;

			let paymentOption = categoryPayments.find((obj) => {
				return obj.categoryId == categoryId;
			});

			return paymentOption != null;
		},
		paymentIcon(categoryId) {
			// Check if user has valid category payment or directory payment (promo code)
			let success =
				this.directoryPaymentSuccess() ||
				this.categoryPaymentSuccess([categoryId]);

			return success ? "mdi-lock-open-variant" : "mdi-lock";
		},
		backOutOfCategory() {
			this.active = [
				this.getParentCategoryId(this.categories, this.currentCategory),
			];
		},
	},
	watch: {
		active: {
			handler() {
				this.$store.commit(
					"directory/SET_CURRENT_CATEGORY",
					this.active[0] ?? null
				);
			},
		},
		currentCategory: {
			handler() {
				// Set active category when breadcrumb clicked
				this.active = this.currentCategory
					? [this.currentCategory]
					: [];

				if (this.currentCategory !== null) {
					// Open all categories to the current category so the user can easily see all the subcategories of the active category
					let pathToActiveCategory = this.getPathToCategory(
						this.categories,
						this.currentCategory
					);
					// This is just a way to take a union of two arrays.
					// The reason for using a union instead of a simple push is that we don't want duplicates of opened categories as they could have already been opened by the user.
					this.openCategories = [
						...new Set([
							...this.openCategories,
							...pathToActiveCategory,
						]),
					];
				}

				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
		},
	},
};
</script>

<style>
.back-button {
	min-width: unset !important;
	padding: 2px 4px 2px 4px;
	border: solid;
}

.category-button {
	padding: 2px 10px 2px 10px;
	border: solid;
}

.category-button:hover,
.back-button:hover {
	background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.v-treeview-node {
	cursor: pointer;
}

.v-treeview-node__root.v-treeview-node--active {
	pointer-events: none;
}

.v-treeview-node__toggle {
	pointer-events: all;
}

.v-treeview-node__toggle.v-icon {
	color: inherit !important;
}
</style>
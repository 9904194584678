<template>
	<v-row>
		<v-col
			v-for="i in cardAmount"
			:key="i"
			class="item"
			cols="12"
			:sm="isTile ? '6' : '12'"
			:md="mdCardSize"
			:lg="lgCardSize"
		>
			<component :is="cardType" />
		</v-col>
	</v-row>
</template>

<script>
import SkeletonCardTile from "@/components/cards/SkeletonCardTile.vue";
import SkeletonCardList from "@/components/cards/SkeletonCardList.vue";
import CardsDisplayType from "../../enums/CardsDisplayType";

export default {
	name: "SkeletonCards",
	components: {
		SkeletonCardTile,
		SkeletonCardList,
	},
	data() {
		return {
			cardType: null,
		};
	},
	mounted() {
		this.cardType =
			this.cardSettings.type === CardsDisplayType.TILE
				? SkeletonCardTile
				: SkeletonCardList;
	},
	computed: {
		cardSettings() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		categorySettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsCategory;
		},
		isTile() {
			return this.cardSettings.type === CardsDisplayType.TILE;
		},
		mdCardSize() {
			const hasLeftCategories = this.categorySettings.leftCategories;

			if (this.isTile) {
				return hasLeftCategories ? 6 : 4;
			} else {
				return hasLeftCategories ? 12 : 6;
			}
		},
		lgCardSize() {
			const hasLeftCategories = this.categorySettings.leftCategories;

			if (this.isTile) {
				return hasLeftCategories ? 4 : 3;
			} else {
				return hasLeftCategories ? 6 : 4;
			}
		},
		cardAmount() {
			const hasLeftCategories = this.categorySettings.leftCategories;

			if (this.$vuetify.breakpoint.xsOnly) {
				return this.isTile ? 2 : 4;
			} else if (this.$vuetify.breakpoint.smOnly) {
				return this.isTile ? 4 : 4;
			} else if (this.$vuetify.breakpoint.mdOnly) {
				if (hasLeftCategories) {
					return this.isTile ? 4 : 4;
				} else {
					return this.isTile ? 6 : 8;
				}
			} else {
				// lgAndUp
				if (hasLeftCategories) {
					return this.isTile ? 6 : 8;
				} else {
					return this.isTile ? 8 : 9;
				}
			}
		},
	},
};
</script>

<style scoped>
.item {
	padding-top: 0px;
	padding-bottom: 20px;
}
</style>
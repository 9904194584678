<template>
    <v-skeleton-loader elevation="2" type="image, article"></v-skeleton-loader>
</template>

<script>
export default {
    name: "SkeletonCardTile",
};
</script>

<style scoped>
.v-skeleton-loader {
    background-color: white;
}
</style>
<template>
    <v-footer class="align-start" :style="footerStyle">
        <v-container class="footer-container" fluid>
            <component
                v-bind:is="currentFooterComponent"
                @openTextDialog="openTextDialog"
            ></component>
        </v-container>
    </v-footer>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import FooterLayout1 from "@/components/footer/FooterLayout1.vue";
import FooterLayout2 from "@/components/footer/FooterLayout2.vue";

export default {
    name: "DirectoryFooter",
    component: {
        FooterLayout1,
        FooterLayout2,
    },
    computed: {
        settingsFooter() {
            return this.$store.getters["directory/getDirectory"].settingsFooter;
        },
        footerStyle() {
            return {
                backgroundColor: this.settingsFooter.backgroundColor,
                color: this.settingsFooter.textColor,
                zIndex: 5,
            };
        },
        currentFooterComponent() {
            return this.settingsFooter.footerStyle == 0
                ? FooterLayout1
                : FooterLayout2;
        },
    },
    methods: {
        openTextDialog(content) {
            EventBus.$emit("open-text-dialog", content);
        },
    },
};
</script>

<style>
.footer label {
    color: inherit;
}

.footer-text {
    display: block;
    font-size: 13px;
}

.footer-container {
    min-height: 180px;
}
</style>

<style scoped>
.container {
    max-width: 1620px;
}
</style>
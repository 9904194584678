import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { toCamelCase } from "../namingConversion.js";
import locationHelpers from "@/mixins/locationHelpers.js";

Vue.use(Vuex);

const cardsModule = {
	namespaced: true,
	state: {
		cards: null,

		// Global loading to prevent redeeming multiple cards at once
		loading: false,
	},
	mutations: {
		SET_CARDS(state, cards) {
			Vue.set(state, 'cards', toCamelCase(cards));
		},
	},
	actions: {
		/**
		 * Retrieve directory campaigns
		 */
		retrieveCampaigns({ commit, rootGetters }) {
			return new Promise((resolve, reject) => {
				let url = rootGetters["directory/getId"] + "/card";

				let config = {
					params: {
						device_uuid: rootGetters["auth/getDeviceUuid"],
						coordinates: rootGetters["user/getCoordinates"] // If coordinates are null they won't be added
					},
					headers: { Authorization: `Bearer ${rootGetters["auth/getAccessToken"]}` }
				};

				axios
					.get(url, config)
					.then((resp) => {
						let cards = resp.data;

						// Calculate distances if locations enabled
						if (allowLocations(rootGetters))
							cards = locationHelpers.methods.calculateDistances(cards, rootGetters["user/getCoordinates"]);

						commit("SET_CARDS", cards);

						resolve();
					})
					.catch((err) => {
						reject(err);
					});
			});
		},
		favorite({ rootGetters }, { cardId, favorite }) {
			return new Promise((resolve, reject) => {
				let url = rootGetters["directory/getId"] + "/card/" + cardId + "/favorite";

				axios
					.post(url, { favorite: favorite }, headers(rootGetters))
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error.response.data);
					});
			});
		},
		redeem({ state, rootGetters }, cardId) {
			return new Promise((resolve, reject) => {
				let url = rootGetters["directory/getId"] + "/card/" + cardId + "/redeem";

				axios
					.post(url, { device_uuid: rootGetters["auth/getDeviceUuid"] }, headers(rootGetters))
					.then((resp) => {
						// Set single_use to card
						let card = state.cards.find(obj => obj.id == cardId);

						if (card) card.redeemUrl = card.campaign.url + resp.data.postfix;

						resolve();
					}).catch((error) => {
						reject(error.response.data);
					});
			});
		},
		setLoading({ state }, loading) {
			state.loading = loading;
		}
	},
	getters: {
		getCards(state) {
			return state.cards;
		},
		loading(state) {
			return state.loading;
		}
	},
};

function headers(getters) {
	let headers = { Authorization: `Bearer ${getters["auth/getAccessToken"]}` };

	return { headers: headers }
}

function allowLocations(rootGetters) {
	let directory = rootGetters["directory/getDirectory"];
	let coordinates = rootGetters["user/getCoordinates"];

	return directory.geolocation && coordinates;
}

export default cardsModule;

<template>
	<v-app-bar :color="settingsHeader.color" :app="!screenshot" dense>
		<v-container class="pa-0 pl-md-4 pr-md-4 fill-height">
			<!-- Header logo image -->
			<v-img
				:src="settingsHeader.logo"
				class="cursor-pointer"
				max-width="180"
				width="25%"
				height="38"
				contain
				@click="logoClicked"
			/>

			<v-spacer />
			<div id="app-bar-menu">
				<v-row align="center" justify="center" no-gutters>
					<v-col v-if="settingsHeader.showSearch">
						<search-bar />
					</v-col>

					<!-- Description -->
					<v-col v-if="settingsHeader.showDescription">
						<v-icon
							:color="settingsHeader.contentColor"
							class="ml-2"
							@click="openDialog('open-text-dialog')"
						>
							mdi-information-outline
						</v-icon>
					</v-col>

					<!-- Locations -->
					<v-col v-if="settingsHeader.showLocations">
						<v-icon
							:color="settingsHeader.contentColor"
							class="ml-2"
							@click="openDialog('open-locations-dialog')"
						>
							mdi-map-marker-outline
						</v-icon>
					</v-col>

					<v-col v-if="settingsAuthentication.requireAuthentication">
						<!-- Sign in -->
						<v-icon
							v-if="!authenticated"
							:color="settingsHeader.contentColor"
							class="ml-2"
							icon
							@click="openDialog('open-authentication-dialog')"
						>
							mdi-login
						</v-icon>

						<!-- Account settings -->
						<v-menu v-else offset-y transition="slide-y-transition">
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									class="ml-2"
									v-bind="attrs"
									v-on="on"
									:color="settingsHeader.contentColor"
								>
									mdi-account-outline
								</v-icon>
							</template>

							<v-list class="pa-0" width="200">
								<v-list-item
									class="small"
									@click="$refs.profileDialog.open()"
									dense
								>
									<v-list-item-title>
										{{ $t("authentication.profile") }}
									</v-list-item-title>
								</v-list-item>

								<v-list-item
									v-if="settingsPayment.requirePayment"
									class="small"
									@click="$refs.paymentStatusDialog.open()"
									dense
								>
									<v-list-item-title>
										{{ $t("payments.payment") }}
									</v-list-item-title>
								</v-list-item>

								<v-list-item
									class="small"
									@click="logout"
									dense
								>
									<v-list-item-title class="red--text">
										{{ $t("authentication.log-out") }}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<div v-if="authenticated" id="dialogs">
							<profile-dialog ref="profileDialog" />
							<payment-status-dialog ref="paymentStatusDialog" />
							<logging-out ref="loggingOut" />
						</div>
					</v-col>
				</v-row>
			</div>
		</v-container>
		<div class="custom-customer-text" v-if="directory.code == 'shRam6Aj2z'">
			<a href="tel:+18556785953" class="text-success">
				Call (855) 678-5953 Now To Double Your Savings On All Show
				Coupons! Best Seats Available. Call Today!
			</a>
		</div>
	</v-app-bar>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import ProfileDialog from "@/components/authentication/ProfileDialog.vue";
import PaymentStatusDialog from "@/components/payments/PaymentStatusDialog.vue";
import LoggingOut from "@/components/authentication/LoggingOut.vue";
import SearchBar from "@/components/input/SearchBar.vue";

export default {
	name: "DirectoryHeader",
	components: {
		ProfileDialog,
		PaymentStatusDialog,
		LoggingOut,
		SearchBar,
	},
	computed: {
		screenshot() {
			return this.$store.getters["directory/isScreenshot"];
		},
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		settingsHeader() {
			return this.directory.settingsHeader;
		},
		settingsAuthentication() {
			return this.directory.settingsAuthentication;
		},
		settingsPayment() {
			return this.directory.settingsPayment;
		},
		authenticated() {
			return this.$store.getters["auth/authenticated"];
		},
	},
	mounted() {
		// Check if description dialog needs to open on initial page load
		if (
			this.settingsHeader.showDescription &&
			this.settingsHeader.showDescriptionOnLoad
		) {
			this.$nextTick(() => {
				this.openDialog("open-text-dialog");
			});
		}
	},
	methods: {
		openDialog(type) {
			const content =
				type == "open-text-dialog" ? this.directory.description : null;

			EventBus.$emit(type, content);
		},
		logoClicked() {
			this.$store.commit("directory/SET_CURRENT_CATEGORY", null);
		},
		logout() {
			this.$refs.loggingOut.open();

			this.$store.dispatch("auth/logout").then(() => {
				location.reload();
			});
		},
	},
};
</script>

<style scoped>
.v-app-bar {
	z-index: 6 !important;
}

.container {
	max-width: 1440px !important;
}

.small {
	height: 38px !important;
	min-height: unset !important;
}
.custom-customer-text {
	background-color: #c8e6c9;
	font-weight: bold;
	padding: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	position: absolute;
	top: 60%;
	width: 54%;
	left: 19%;
}
.custom-customer-text a {
	color: #1b5e20 !important;
}
@media screen and (max-width: 600px) {
	.custom-customer-text {
		width: 100%;
		left: 0%;
	}
}
@media screen and (min-width: 1441px) {
	.custom-customer-text {
		width: 770px;
	}
}
</style>
import store from '@/store/index';

export default {
	computed: {
		layout() {
			return store.getters["directory/getDirectory"].settingsCard;
		},

		buttonStyle() {
			return {
				color: this.layout.buttonTextColor + " !important",
				backgroundColor: this.layout.buttonBackgroundColor,
				borderColor: this.layout.buttonBorderColor,
				borderRadius: this.layout.buttonBorderRadius + "px",
				width: "100%",
			};
		},

		buttonStyleIgnoreWidth() {
			return {
				color: this.layout.buttonTextColor + " !important",
				backgroundColor: this.layout.buttonBackgroundColor,
				borderColor: this.layout.buttonBorderColor,
				borderRadius: this.layout.buttonBorderRadius + "px",
			};
		},

		cardStyle() {
			let style = {
				borderRadius: this.layout.borderRadius + "px",
				backgroundColor: this.layout.backgroundColor,
			};

			style.cursor = this.layout.cardClickable ? "pointer" : "default";

			if (this.layout.shadow === "default") {
				style.boxShadow =
					"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)";
			} else if (this.layout.shadow === "spread") {
				style.boxShadow =
					"0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)";
			}

			if (this.layout.border)
				style.border = `${this.layout.borderThickness}px ${this.layout.border} ${this.layout.borderColor}`;

			return style;
		},

		recommendedChipStyle() {
			let style = {
				borderRadius: this.layout.recommendedBorderRadius + "px",
			};

			if (this.layout.recommendedPosition == "top-left") {
				style.top = style.left = 0;
			} else if (this.layout.recommendedPosition == "top-right") {
				style.top = style.right = 0;
			} else if (this.layout.recommendedPosition == "bottom-right") {
				style.bottom = style.right = 0;
			} else if (this.layout.recommendedPosition == "bottom-left") {
				style.bottom = style.left = 0;
			}

			return style;
		},
	}
}
<template>
	<div id="directory">
		<!-- Fullscreen loader -->
		<v-overlay :value="loading" opacity="1" color="#999999">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<!-- Actual directory -->
		<div v-if="!loading" id="application">
			<div id="content" class="d-flex flex-column">
				<!-- Directory header -->
				<directory-header v-if="directory.settingsHeader.header" />

				<!-- Directory content -->
				<v-container class="flex-grow-1">
					<directory-content />
				</v-container>

				<!-- Directory footer -->
				<directory-footer v-if="directory.settingsFooter.footer" />
			</div>

			<!-- PWA iOS fallback -->
			<add-to-homescreen v-if="directory.pwa" />

			<!-- Do not show dialogs if screenshot -->
			<div v-if="!screenshot" id="dialogs">
				<!-- Authentication dialog -->
				<authentication-dialog
					v-if="
						!authenticated &&
						settingsAuthentication.requireAuthentication
					"
				/>

				<!-- Payment dialog -->
				<payment-dialog
					v-if="authenticated && settingsPayment.requirePayment"
				/>

				<!-- Location dialog -->
				<locations-dialog
					v-if="
						directory.settingsHeader.showLocations ||
						directory.settingsCard.showLocation
					"
				/>

				<!-- Text dialog (multiple use) -->
				<text-dialog />
			</div>

			<scroll-to-top />
		</div>

		<v-snackbar
			v-model="event"
			bottom
			right
			:timeout="5000"
			:color="eventColor"
		>
			<v-icon dark class="mr-2">{{ eventIcon }}</v-icon>
			{{ eventText }}
		</v-snackbar>
	</div>
</template>

<script>
import Vue from "vue";
import VueGtag from "vue-gtag";
import VueGtm from "vue-gtm";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import LoadScript from "vue-plugin-load-script";
import WebFontLoader from "webfontloader";

import DirectoryHeader from "@/components/DirectoryHeader.vue";
import DirectoryContent from "@/components/DirectoryContent.vue";
import DirectoryFooter from "@/components/DirectoryFooter.vue";

import AuthenticationDialog from "@/components/authentication/AuthenticationDialog.vue";
import PaymentDialog from "@/components/payments/PaymentDialog.vue";
import TextDialog from "@/components/TextDialog.vue";
import ScrollToTop from "@/components/misc/ScrollToTop.vue";
import AddToHomescreen from "@/components/misc/AddToHomescreen.vue";

// Lazy load locations dialog only when used
// Performance optimization for the mapbox gl module
const LocationsDialog = () =>
	import("@/components/locations/LocationsDialog.vue");

// Font map of Google fonts
const fontMap = {
	"Indie Flower": "Indie Flower",
	"Open Sans": "Open Sans",
	Roboto: "Roboto",
	Montserrat: "Montserrat",
	Oxygen: "Oxygen",
	Lobster: "Lobster",
	Bangers: "Bangers",
	Righteous: "Righteous",
	"Passion One": "Passion One",
	'"Sanchez", serif': "Sanchez",
	'"Pacifico", cursive': "Pacifico",
	'"Tillana", cursive': "Tillana",
	'"Mountains of Christmas", cursive': "Mountains of Christmas",
	'"Calligraffitti", cursive': "Calligraffitti",
	'"Covered By Your Grace", cursive': "Covered By Your Grace",
	'"Gloria Hallelujah", cursive': "Gloria Hallelujah",
	'"Leckerli One", cursive': "Leckerli One",
	'"Courgette", cursive': "Courgette",
	'"Yanone Kaffeesatz", sans-serif': "Yanone Kaffeesatz",
	'"Lato", sans-serif': "Lato",
	'"Rubik", sans-serif': "Rubik",
	'"Goldman", cursive': "Goldman",
	Ruda: "Ruda",
	'"Arimo", sans-serif': "Arimo",
	'"Secular One", sans-serif': "Secular One",
	'"Suez One", serif': "Suez One",
};

export default {
	name: "Directory",
	components: {
		DirectoryHeader,
		DirectoryContent,
		DirectoryFooter,
		AuthenticationDialog,
		PaymentDialog,
		LocationsDialog,
		TextDialog,
		ScrollToTop,
		AddToHomescreen,
	},
	data() {
		return {
			loading: true,
			event: false,
		};
	},
	computed: {
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		screenshot() {
			return this.$store.getters["directory/isScreenshot"];
		},
		authenticated() {
			return this.$store.getters["auth/authenticated"];
		},
		settingsLayout() {
			return this.directory.settingsLayout;
		},
		settingsAuthentication() {
			return this.directory.settingsAuthentication;
		},
		settingsPayment() {
			return this.directory.settingsPayment;
		},
		eventColor() {
			return this.$route.query.event == "promocode_success"
				? "success"
				: "error";
		},
		eventText() {
			let event = this.$route.query.event;
			if (event == "payment_cancelled")
				return this.$t("payments.snackbar.payment-cancelled");
			else if (event == "payment_failed")
				return this.$t("payments.snackbar.payment-failed");
			else if (event == "promocode_success")
				return this.$t("payments.snackbar.access-code-success");

			return "";
		},
		eventIcon() {
			return this.$route.query.event == "promocode_success"
				? "mdi-checkbox-marked-circle"
				: "mdi-alert-circle";
		},
	},
	mounted() {
		// Get code from URL
		const code = this.$route.params.directoryCode;

		this.$store
			.dispatch("directory/load", code)
			.then(async () => {
				// Login using SSO token
				await this.tokenLogin();

				// Check if requires authentication
				if (this.settingsAuthentication.requireAuthentication) {
					if (this.$store.getters["auth/hasAccessToken"]) {
						await this.$store.dispatch("user/me");
					}
				} else {
					// Be sure to clear authenticated in case still logged in
					this.$store.commit("auth/SET_AUTHENTICATED", false);
				}

				// Prevent triggering catch when errors in configureSettings()
				try {
					this.configureSettings();
				} catch (e) {
					console.log(e);
				}

				// Open category directly if the route specified a category
				const openCategory = this.$route.query.category;
				if (openCategory) {
					const openCategoryId = this.$store.getters[
						"directory/getCategories"
					].find(
						(category) =>
							category.name.toLowerCase() ==
							openCategory.toLowerCase()
					)?.id;

					if (openCategoryId) {
						this.$store.commit(
							"directory/SET_CURRENT_CATEGORY",
							openCategoryId
						);
					}
				}
			})
			.catch((e) => {
				console.error(e);
				this.$router.push({ name: "NotFound" });
			})
			.finally(() => {
				this.loading = false;
			});

		this.event = this.$route.query.event != undefined;

		// Remove event from query
		if (this.event)
			window.history.replaceState(null, null, window.location.pathname);
	},
	methods: {
		/**
		 * Check if token in url and tries to login with token
		 */
		async tokenLogin() {
			const urlParams = new URLSearchParams(location.search);

			if (urlParams.has("token")) {
				await this.$store
					.dispatch("auth/loginToken", urlParams.get("token"))
					.catch(() => {}) // Required but leave empty
					.finally(() => {
						history.pushState({}, null, `${this.$route.path}`);
					});
			}
		},
		configureSettings() {
			// Ask for coordinates
			if (
				this.directory.geolocation ||
				this.directory.settingsCard.order === "close-far"
			)
				this.$store.dispatch("user/coordinates");

			// Load font
			this.loadFont();

			// Languages
			this.$i18n.locale = this.directory.language;

			if (this.settingsLayout.customCss) this.setCustomCss(); // Add custom CSS
			if (this.directory.googleAnalytics) this.loadGoogleAnalytics(); // Enable Google Analytics
			if (this.directory.googleTagManager) this.loadGoogleTagManager(); // Enable Google Recaptcha
			if (this.directory.facebookPixel) this.loadFacebookPixel(); // Enable Facebook Pixel
			if (this.directory.customJs) this.setCustomJs(); // Add custom Js
		},
		loadFont() {
			if (this.settingsLayout.fontFamily in fontMap) {
				WebFontLoader.load({
					google: {
						families: [
							fontMap[this.settingsLayout.fontFamily] +
								":300,400,500",
						],
					},
				});
			}
		},
		loadGoogleAnalytics() {
			Vue.use(VueGtag, {
				config: { id: this.directory.googleAnalytics },
			});
		},
		loadGoogleTagManager() {
			try {
				Vue.use(VueGtm, {
					id: this.directory.googleTagManager,
					debug: true, // Whether or not display console logs debugs (optional)
					loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
				});
			} catch (err) {
				console.log(err);
			}
		},
		loadFacebookPixel() {
			Vue.use(LoadScript);

			Vue.loadScript("/js/facebookPixel").then(() => {
				Vue.use(VueFacebookPixel);

				Vue.analytics.fbq.init(this.directory.facebookPixel);

				Vue.analytics.fbq.event("PageView", {
					content_name: "Directory Opened",
				});
			});
		},
		setCustomCss() {
			let css = this.settingsLayout.customCss;
			let head = document.getElementsByTagName("head")[0];

			let style = document.createElement("style");
			style.id = "custom_css";
			style.type = "text/css";
			style.innerHTML = css;
			head.appendChild(style);
		},
		setCustomJs() {
			let script = "";

			if (this.directory.customJs.startsWith("<script")) {
				script = document
					.createRange()
					.createContextualFragment(this.directory.customJs);
			} else {
				script = document.createElement("script");
				script.type = "text/javascript";
				script.text = this.directory.customJs;
				script.async = true;
				script.dataset.cfasync = false;
			}

			document.body.appendChild(script);
		},
	},
};
</script>

<style scoped>
#directory {
	height: 100%;
	overflow: hidden; /* Used for screenshotlayer */
}

#application,
#content {
	height: inherit;
}

.container {
	max-width: 1440px !important;
}
</style>

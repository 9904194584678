<template>
    <p class="transition mb-0">
        <span class="card-content transition" v-html="formattedString" />
        <span v-show="text.length > maxChars">
            <a
                :href="link"
                id="readmore"
                v-show="!isReadMore"
                v-on:click.prevent.stop="triggerReadMore($event, true)"
                v-html="moreStr"
            />
            <a
                :href="link"
                id="readmore"
                v-show="isReadMore"
                v-on:click.prevent.stop="triggerReadMore($event, false)"
                v-html="lessStr"
            />
        </span>
    </p>
</template>

<script>
export default {
    props: {
        moreStr: {
            type: String,
            default: "read more",
        },
        lessStr: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            default: "#",
        },
        maxChars: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            isReadMore: false,
        };
    },

    computed: {
        formattedString() {
            var val_container = this.text;

            if (!this.isReadMore && this.text.length > this.maxChars) {
                val_container =
                    val_container.substring(0, this.maxChars) + "...";
            }

            return val_container;
        },
    },

    methods: {
        triggerReadMore(e, b) {
            if (this.link == "#") e.preventDefault();

            if (this.lessStr !== null || this.lessStr !== "")
                this.isReadMore = b;

            // IMPORTANT: Redraw cards in order to size "read more"
            this.$nextTick(() => {
                this.$redrawVueMasonry();
            });
        },
    },
};
</script>

<style>
.transition {
    transition: height 1s linear;
}
</style>
<template>
    <div id="login">
        <!-- Social login providers -->
        <social-login v-if="allowSocialLogin" />

        <v-alert v-if="error" type="error" text dense>
            <label class="body-2">{{ error }}</label>
        </v-alert>

        <!-- Email/password sign in -->
        <form v-on:submit.prevent="signIn">
            <default-input
                v-model="email"
                :label="$t('input.email')"
                required
            />

            <default-input
                v-model="password"
                type="password"
                :label="$t('input.password')"
                required
                hide-details
            />

            <v-btn
                type="submit"
                class="text-none"
                :class="{ 'mt-6': error == null }"
                :style="buttonStyle"
                large
                block
                depressed
                :loading="loggingIn"
            >
                {{ $t("authentication.sign-in") }}
            </v-btn>

            <!-- Forgot password-->
            <p class="mt-1 text-left">
                <a @click="$emit('openForgotPassword')">
                    {{ $t("authentication.forgot-password") }}
                </a>
            </p>
        </form>

        <div class="text-center mt-2">
            <label class="text--primary">
                {{ $t("authentication.no-member") }}
                <a @click="$emit('openRegister')">
                    {{ $t("authentication.sign-up") }}
                </a>
            </label>
        </div>
    </div>
</template>

<script>
import DefaultInput from "@/components/input/DefaultInput.vue";
import SocialLogin from "@/components/authentication/SocialLogin.vue";

export default {
    name: "LoginScreen",
    components: {
        DefaultInput,
        SocialLogin,
    },
    data() {
        return {
            email: null,
            password: null,

            loggingIn: false,
            error: null,
        };
    },
    computed: {
        authentication() {
            return this.$store.getters["directory/getDirectory"]
                .settingsAuthentication;
        },
        buttonStyle() {
            return {
                color: this.authentication.buttonTextColor + " !important",
                backgroundColor: this.authentication.buttonBackgroundColor,
            };
        },
        allowSocialLogin() {
            return this.authentication.facebook || this.authentication.google;
        },
    },
    methods: {
        signIn() {
            this.loggingIn = true;
            this.error = null;

            this.$store
                .dispatch("auth/login", {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    location.reload();
                })
                .catch((error) => {
                    this.error = error.message;
                    this.loggingIn = false;
                });
        },
    },
};
</script>

<style>
a {
    text-decoration: none;
}
</style>

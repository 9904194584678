<template>
    <v-checkbox class="mt-0" hide-details dense required>
        <template v-slot:label>
            <label>
                {{ $t("input.agree-to") }} <a @click.stop="open">{{ label }}</a>
            </label>
        </template>
    </v-checkbox>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
    name: "CustomCheckbox",
    props: ["type", "content"],
    computed: {
        label() {
            return this.type == "tac"
                ? this.$t("input.tac")
                : this.$t("input.privacy-policy");
        },
    },
    methods: {
        open() {
            EventBus.$emit("open-text-dialog", this.content);
        },
    },
};
</script>

<style>
</style>
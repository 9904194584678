<template>
	<div id="breadcrumbs">
		<h3 class="d-inline" @click="overviewSelected">
			<label class="clickable">
				{{ settingsCategory.breadcrumbsText }}
			</label>
		</h3>

		<h3 v-for="(id, index) in currentCategories" :key="id" class="d-inline">
			<label class="mr-2 ml-2">
				<v-icon style="color: inherit">mdi-chevron-right</v-icon>
			</label>
			<label class="clickable" @click="categorySelected(id, index + 1)">
				{{ categoryNames[id] }}
			</label>
		</h3>
	</div>
</template>

<script>
import categoryHelpers from "@/mixins/categoryHelpers.js";

export default {
	name: "CategoryBreadcrumbs",
	data() {
		return {
			categoryNames: {},
			currentCategories: [],
		};
	},
	mixins: [categoryHelpers],
	mounted() {
		// Recursive function
		this.setCategoryNames(this.categories);
		this.updateCurrentCategory();
	},
	computed: {
		settingsCategory() {
			return this.$store.getters["directory/getDirectory"]
				.settingsCategory;
		},
		categories() {
			return this.$store.getters["directory/getCategories"];
		},
		currentCategory() {
			return this.$store.getters["directory/getCurrentCategory"];
		},
	},
	methods: {
		overviewSelected() {
			this.$store.commit("directory/SET_CURRENT_CATEGORY", null);
		},
		categorySelected(id, index) {
			// Do not if clicked current category
			if (index == this.currentCategories.length) return;

			this.$store.commit("directory/SET_CURRENT_CATEGORY", id);
		},
		setCategoryNames(categories) {
			for (let i = 0; i < categories.length; ++i) {
				let category = categories[i];

				this.categoryNames[category.id] = category.name;

				if (category.children) this.setCategoryNames(category.children);
			}
		},
		updateCurrentCategory() {
			if (this.currentCategory) {
				this.currentCategories = this.getPathToCategory(
					this.categories,
					this.currentCategory
				);
			} else {
				this.currentCategories = [];
			}
		},
	},
	watch: {
		currentCategory: {
			handler() {
				this.updateCurrentCategory();
			},
		},
	},
};
</script>

<style scoped>
.clickable {
	cursor: pointer;
	white-space: nowrap;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"addtohomescreen",class:[
            'add-to-homescreen-container',
            _vm.opened
                ? 'add-to-homescreen-visible'
                : 'add-to-homescreen-hidden' ]},[_c('v-btn',{staticClass:"dialog__close",attrs:{"icon":"","x-small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"icon-container"},[(_vm.iconImage)?_c('span',{staticClass:"icon",style:({
                        'background-image': 'url(' + _vm.iconImage + ')',
                    })},[(!_vm.iconImage)?[_vm._v(_vm._s(_vm.firstCharTitle))]:_vm._e()],2):_c('span',{staticClass:"icon",style:({
                        backgroundColor: _vm.iconColor,
                        color: _vm.iconTextColor,
                    })},[_vm._v(_vm._s(_vm.firstCharTitle)+" ")])]),_c('div',{staticClass:"col"},[_c('span',{staticClass:"app-title",style:({ color: _vm.getOpt('titleColor') })},[_vm._v(_vm._s(_vm.getOpt("title") ? _vm.getOpt("title") : _vm.appTitle))]),_c('br'),_c('span',{staticClass:"app-content",style:({ color: _vm.getOpt('contentColor') }),domProps:{"innerHTML":_vm._s(_vm.getOpt('content') || _vm.appUrl)}})])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"btn-container"},[_c('button',{staticClass:"add-button",style:({
                            color: _vm.getOpt('buttonTextColor'),
                            'background-color': _vm.getOpt('buttonColor'),
                        }),on:{"click":_vm.addToHomescreen}},[_vm._v(" "+_vm._s(_vm.localizedString.addToHomescreen.toUpperCase())+" ")])])])])],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-btn',{staticClass:"dialog__close",attrs:{"icon":"","x-small":""},nativeOn:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title'),_c('v-card-text',{staticClass:"text-center"},[(_vm.compatible)?_c('div',[_c('div',{staticClass:"text-subtitle-2 mb-3",domProps:{"innerHTML":_vm._s(
                            _vm.parseMessage(_vm.localizedString.addMessages.ios1)
                        )}}),_c('div',{staticClass:"text-subtitle-2",domProps:{"innerHTML":_vm._s(
                            _vm.parseMessage(_vm.localizedString.addMessages.ios2)
                        )}})]):_c('div',{staticClass:"text-subtitle-2",domProps:{"innerHTML":_vm._s(_vm.localizedString.notCompatible)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
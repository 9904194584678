<template>
    <v-dialog v-model="dialog" width="380" persistent>
        <v-card>
            <v-card-text class="text-center pt-10">
                <v-progress-circular
                    indeterminate
                    size="50"
                ></v-progress-circular>

                <br />

                <h3 class="mt-7 mb-4">
                    {{ $t("authentication.provider-connect") }}
                    <label class="text-capitalize">{{ provider }}</label>
                </h3>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "LoggingIn",
    data() {
        return {
            dialog: false,
            provider: null,
        };
    },
    methods: {
        open(provider) {
            this.provider = provider;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    },
};
</script>
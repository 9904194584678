<template>
	<div id="cards" :class="{ 'pt-2': cardPadding }">
		<!-- Cards while loading -->
		<skeleton-cards v-if="cards == null" />

		<div v-else>
			<!-- Category payment requirement -->
			<v-alert
				v-if="requiresCategoryPayment && !isCategoryPaymentSuccess"
				class="alert-size alert-color"
				type="error"
				text
			>
				{{ $t("content.payment-required") }}
				<v-btn
					class="float-right"
					light
					small
					@click="proceedToPayment"
				>
					{{ $t("button.proceed-to-payment") }}
				</v-btn>
			</v-alert>

			<!-- No offers -->
			<v-alert
				v-if="cards.length == 0"
				class="alert-size alert-color mb-0"
				type="info"
				text
			>
				{{ $t("content.no-offers-found") }}
			</v-alert>

			<!-- Actual visible cards -->
			<active-cards v-else :cards="paginationCards" />

			<!-- Pagination (optional) -->
			<pagination
				v-if="cardSettings.pagination && cards.length"
				:amount="cards.length"
			/>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import categoryHelpers from "@/mixins/categoryHelpers.js";
import paymentHelpers from "@/mixins/paymentHelpers.js";

import ActiveCards from "@/components/cards/ActiveCards.vue";
import SkeletonCards from "@/components/cards/SkeletonCards.vue";
import Pagination from "@/components/content/Pagination.vue";

export default {
	name: "Cards",
	props: {
		cards: Array,
	},
	components: {
		ActiveCards,
		SkeletonCards,
		Pagination,
	},
	mixins: [categoryHelpers, paymentHelpers],
	computed: {
		paymentSettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsPayment;
		},
		categorySettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsCategory;
		},
		cardSettings() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		currentCategory() {
			return this.$store.getters["directory/getCurrentCategory"];
		},
		currentPage() {
			return this.$store.getters["directory/getCurrentPage"];
		},
		requiresCategoryPayment() {
			// Check if payment is even enabled in the first place
			if (!this.paymentSettings.requirePayment) return false;

			// Can't have category payment if no category selected
			if (this.currentCategory == null) return false;

			// Check if successful directory payment
			// Directory payment always has priority to category payment
			if (this.directoryPaymentSuccess()) return false;

			// Check if payment settings is restricted to category
			if (this.paymentSettings.paymentRestriction != "category")
				return false;

			return this.categoryPaymentOption != null;
		},
		isCategoryPaymentSuccess() {
			// Get all parent categories and the current category
			// If any of these have a valid payment the user should have access to the category
			let categories = this.getPathToCategory(
				this.$store.getters["directory/getCategories"],
				this.currentCategory
			);

			// Check if user has valid category payment
			return this.categoryPaymentSuccess(categories);
		},
		cardPadding() {
			return (
				this.$vuetify.breakpoint.smAndDown ||
				!this.categorySettings.leftCategories
			);
		},
		categoryPaymentOption() {
			let categoryPayments = this.paymentSettings.paymentCategories;

			let categories = this.getPathToCategory(
				this.$store.getters["directory/getCategories"],
				this.currentCategory
			);

			let paymentOption = categoryPayments.find((obj) => {
				return categories.includes(obj.categoryId);
			});

			return paymentOption;
		},
		paginationCards() {
			// Return all card if pagination disabled
			if (!this.cardSettings.pagination) return this.cards;

			let cardsPerPage = this.cardSettings.paginationAmount;

			let end = this.currentPage * cardsPerPage;
			let start = end - cardsPerPage;

			return this.cards.slice(start, end);
		},
	},
	methods: {
		proceedToPayment() {
			EventBus.$emit("open-payment-dialog", this.categoryPaymentOption);
		},
	},
	watch: {
		// Set page to 1 if current category changes
		currentCategory: function () {
			this.$store.commit("directory/SET_CURRENT_PAGE", 1);
		},
	},
};
</script>

<style>
.alert-size .v-alert__content {
	font-size: 16px !important;
}

.alert-color {
	background-color: #ffffff !important;
}
</style>
export default {
	en_GB: {
		addToHomescreen: "Add to home screen",
		addMessages: {
			//ios1: `1. On Safari browser, open Share menu`,
			//ios2: `2. Tap on "Add to Home Screen" button`,
			ios1: `1. Tap [SHARE_ICON] below to open the share menu`,
			ios2: `2. Select <strong>[HOMESCREEN_ICON] Add to Home Screen</strong>`,
			android: `1. Open browser parameters
  2. Tap on "Add to homescreen"`,
			windows: {
				chrome: "Click on (+) button to the right of your navigation bar.",
				firefox: `1. Resize your browser so you can see your desktop
  2. Drag and drop the (i) button left of your navigation bar to your desktop`,
			},
			macos: {
				chrome: "Click on (+) button to the right of your navigation bar.",
				safari: `1. Resize your browser so you can see your desktop
  2. Drag & drop the earth icon left of your notification bar to your desktop`,
				firefox: `1. Resize your browser so you can see your desktop
  2. Drag and drop the (i) button left of your navigation bar to your desktop`,
			},
			others: `Looks like your browser doesn't support add to homescreen natively. Feel free to update/change your browser.`,
		},
		notCompatible: "Your current browser will only allow you to view this app. Use <strong>Safari</strong> to add the app to you <strong>Home Screen</strong>"
	},
	fr_FR: {
		addToHomescreen: "Installer l'application",
		addMessages: {
			ios1: `1. Sur le navigateur Safari, ouvrir le menu de partage`,
			ios2: `2. Appuyez sur le bouton "Sur l'écran d'accueil"`,
			android: `1. Ouvrez les paramètres de la page
  2. Appuyez sur le bouton "Ajouter à l'écran d'accueil"`,
			windows: {
				chrome:
					"Cliquez sur le bouton (+) présent à droite dans votre barre de navigation",
				firefox: `1. Redimensionnez votre navigateur pour voir votre bureau
  2. Cliquez & glissez l'icône (i) présent à gauche de votre barre de navigation sur votre bureau`,
			},
			macos: {
				chrome:
					"Cliquez sur le bouton (+) présent à droite dans votre barre de navigation",
				safari: `1. Redimensionnez votre navigateur pour voir votre bureau
  2. Cliquez & glissez l'icône 'terre' présent à gauche de votre barre de navigation sur votre bureau`,
				firefox: `1. Redimensionnez votre navigateur pour voir votre bureau
  2. Cliquez & glissez l'icône (i) présent à gauche de votre barre de navigation sur votre bureau`,
			},
			others: `Il semblerait que votre navigateur ne supporte pas la fonctionnalité d'ajout à la page d'accueil. Mettez-le à jour ou changez de navigateur.`,
		},
		notCompatible: "Your current browser will only allow you to view this app. Use <strong>Safari</strong> to add the app to you <strong>Home Screen</strong>"
	},
};
<template>
    <v-row v-if="footerCols" justify="center">
        <v-col
            v-if="settingsFooter.footerText"
            cols="12"
            :md="footerCols['md']"
            :lg="footerCols['lg']"
            class="text-center"
        >
            <label class="footer-text" v-html="settingsFooter.footerText" />
        </v-col>

        <v-col
            v-if="hasAppDownload"
            cols="12"
            :md="footerCols['md']"
            :lg="footerCols['lg']"
            class="text-center"
        >
            <label class="mb-4">{{ settingsFooter.downloadAppLabel }}</label>

            <div id="download-badges" class="mt-4 text-center">
                <a
                    v-if="settingsFooter.iosUrl"
                    :href="settingsFooter.iosUrl"
                    class="mr-2"
                    target="_blank"
                >
                    <v-img
                        class="d-inline-block mr-2"
                        :src="require(`@/assets/appStoreBadge.svg`)"
                        contain
                        width="45%"
                        max-width="140"
                    />
                </a>

                <a
                    v-if="settingsFooter.androidUrl"
                    :href="settingsFooter.androidUrl"
                    target="_blank"
                >
                    <v-img
                        class="d-inline-block"
                        :src="require(`@/assets/googlePlayBadge.svg`)"
                        contain
                        width="45%"
                        max-width="140"
                    />
                </a>
            </div>
        </v-col>

        <v-col
            v-if="hasSocials"
            cols="12"
            :md="footerCols['md']"
            :lg="footerCols['lg']"
            class="text-center"
        >
            <label>{{ settingsFooter.socialsLabel }}</label>

            <div class="mt-3">
                <div
                    v-for="social in socials"
                    :key="social.type"
                    class="d-inline"
                >
                    <a
                        v-if="settingsFooter[social.type]"
                        :href="social.prefix + settingsFooter[social.type]"
                        class="mr-2"
                        :target="social.prefix ? '_self' : '_blank'"
                    >
                        <v-icon :color="settingsFooter.socialsIconColor">
                            {{ social.icon }}
                        </v-icon>
                    </a>
                </div>
            </div>
        </v-col>

        <!-- Links -->
        <v-col cols="12" class="text-center" align-self="baseline">
            <label
                v-if="settingsFooter.tac"
                class="cursor-pointer"
                @click="$emit('openTextDialog', directory.tac)"
            >
                {{ settingsFooter.tacLabel }}
                <label
                    v-if="
                        settingsFooter.privacyPolicy ||
                        settingsFooter.description
                    "
                    class="mr-3 ml-3"
                    >|</label
                >
            </label>

            <label
                v-if="settingsFooter.privacyPolicy"
                class="cursor-pointer"
                @click="$emit('openTextDialog', directory.privacyPolicy)"
            >
                {{ settingsFooter.privacyPolicyLabel }}
                <label v-if="settingsFooter.description" class="mr-3 ml-3"
                    >|</label
                >
            </label>

            <label
                v-if="settingsFooter.description"
                class="cursor-pointer"
                @click="$emit('openTextDialog', directory.description)"
            >
                {{ settingsFooter.descriptionLabel }}
            </label>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "FooterLayout2",
    data() {
        return {
            socials: [
                { type: "email", prefix: "mailto:", icon: "mdi-email" },
                { type: "phone", prefix: "tel:", icon: "mdi-phone" },
                { type: "website", prefix: "", icon: "mdi-web" },
                { type: "whatsapp", prefix: "", icon: "mdi-whatsapp" },
                { type: "facebook", prefix: "", icon: "mdi-facebook" },
                { type: "twitter", prefix: "", icon: "mdi-twitter" },
                { type: "linkedin", prefix: "", icon: "mdi-linkedin" },
                { type: "instagram", prefix: "", icon: "mdi-instagram" },
                { type: "youtube", prefix: "", icon: "mdi-youtube" },
            ],
            footerCols: null,
        };
    },
    computed: {
        directory() {
            return this.$store.getters["directory/getDirectory"];
        },
        settingsFooter() {
            return this.$store.getters["directory/getDirectory"].settingsFooter;
        },
        hasAppDownload() {
            return this.settingsFooter.androidUrl || this.settingsFooter.iosUrl;
        },
        hasSocials() {
            return (
                this.settingsFooter.email ||
                this.settingsFooter.phone ||
                this.settingsFooter.website ||
                this.settingsFooter.whatsapp ||
                this.settingsFooter.facebook ||
                this.settingsFooter.twitter ||
                this.settingsFooter.instagram ||
                this.settingsFooter.linkedin ||
                this.settingsFooter.youtube
            );
        },
    },
    mounted() {
        let amount = 0;
        if (this.settingsFooter.footerText) amount++;
        if (this.hasAppDownload) amount++;
        if (this.hasSocials) amount++;

        if (amount == 3) {
            this.footerCols = { md: 4, lg: 3 };
        } else if (amount == 2) {
            this.footerCols = { md: 4, lg: 4 };
        } else {
            this.footerCols = { md: 8, lg: 6 };
        }
    },
};
</script>

<style>
</style>
<template>
    <div id="card-description">
        <read-more
            v-if="cardSettings.expandDescription"
            more-str="Read more"
            less-str="Read less"
            :text="text"
            :max-chars="150"
        ></read-more>

        <label v-else class="card-content cursor-inherit" v-html="text" />
    </div>
</template>

<script>
import ReadMore from "@/components/misc/ReadMore.vue";

export default {
    name: "CardDescription",
    props: {
        text: String,
    },
    components: {
        ReadMore,
    },
    computed: {
        cardSettings() {
            return this.$store.getters["directory/getDirectory"].settingsCard;
        },
    },
};
</script>

<style>
</style>
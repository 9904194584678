<template>
    <v-dialog v-model="dialog" width="400" persistent>
        <v-card>
            <v-card-title>
                <v-btn
                    class="dialog__close"
                    icon
                    x-small
                    @click.native="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                {{ $t("authentication.delete-account") }}
            </v-card-title>

            <v-card-text v-if="loading" class="text-center">
                <v-progress-circular
                    indeterminate
                    size="32"
                ></v-progress-circular>
            </v-card-text>

            <v-card-text v-else>
                <div
                    v-if="directories && directories.length > 1"
                    id="multiple-directories"
                >
                    <v-alert type="error" class="mb-2" text dense>
                        <label class="body-2">
                            {{ $t("authentication.delete-account-linked") }}
                        </label>
                    </v-alert>

                    <v-list class="pa-0">
                        <v-list-item
                            v-for="directory in directories"
                            :key="directory.id"
                            class="list-item"
                        >
                            <v-list-item-icon class="text-center">
                                <v-img
                                    width="85"
                                    height="50"
                                    contain
                                    :src="directory.settingsHeader.logo"
                                ></v-img>
                            </v-list-item-icon>

                            <v-list-item-content class="text-center">
                                <v-list-item-title
                                    v-html="directory.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle>
                                    <a :href="directory.url" target="_blank">
                                        {{
                                            $t("authentication.visit-directory")
                                        }}
                                    </a>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>

                <label v-else class="text--primary">
                    {{ $t("authentication.delete-confirmation") }}
                </label>
            </v-card-text>

            <v-card-actions class="pt-0">
                <v-btn
                    class="white--text text-none"
                    color="error"
                    depressed
                    @click="deleteAccount()"
                    :loading="deleting"
                    :disabled="loading"
                >
                    {{ $t("authentication.delete-account") }}
                </v-btn>

                <v-spacer />

                <v-btn
                    class="text-none"
                    depressed
                    outlined
                    @click="dialog = false"
                    :disabled="deleting || loading"
                >
                    {{ $t("button.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DeleteConfirmation",
    data() {
        return {
            dialog: false,
            loading: true,
            deleting: false,

            directories: [],
        };
    },
    methods: {
        open() {
            // Get other potential directories
            this.$store
                .dispatch("user/directories")
                .then((resp) => {
                    this.directories = resp;
                })
                .finally(() => {
                    this.loading = false;
                });

            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        deleteAccount() {
            this.deleting = true;

            this.$store.dispatch("user/delete").finally(() => {
                location.reload();
            });
        },
    },
};
</script>

<style scoped>
.list-item {
    border-radius: 4px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-top: 10px;
}
</style>
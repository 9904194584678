<template>
	<form v-on:submit.prevent="register">
		<!-- First name -->
		<default-input
			v-if="settingsAuthentication.firstName"
			v-model="form.firstName"
			:label="
				$t('input.first-name') +
				(settingsAuthentication.firstNameRequire ? '*' : '')
			"
			:required="settingsAuthentication.firstNameRequire"
		/>

		<!-- Last name -->
		<default-input
			v-if="settingsAuthentication.lastName"
			v-model="form.lastName"
			:label="
				$t('input.last-name') +
				(settingsAuthentication.lastNameRequire ? '*' : '')
			"
			:required="settingsAuthentication.lastNameRequire"
		/>

		<!-- Email -->
		<default-input
			v-model="form.email"
			type="email"
			:label="$t('input.email') + '*'"
			required
			:error="errors.email"
		/>

		<!-- Phone -->
		<default-phone-input
			v-if="settingsAuthentication.phone"
			v-model="form.phone"
			@object="(object) => (phoneObject = object)"
			:label="
				$t('input.phone') + (settingsAuthentication.phoneRequire ? '*' : '')
			"
			:required="settingsAuthentication.phoneRequire"
			:error="errors.phone"
		/>

		<!-- Birth date -->
		<default-date-picker
			v-if="settingsAuthentication.birthDate"
			v-model="form.birthDate"
			:label="
				$t('input.birth-date') +
				(settingsAuthentication.birthDateRequire ? '*' : '')
			"
			:required="settingsAuthentication.birthDateRequire"
		/>

		<!-- Address -->
		<default-input
			v-if="settingsAuthentication.address"
			v-model="form.address"
			:label="
				$t('input.address') + (settingsAuthentication.addressRequire ? '*' : '')
			"
			:required="settingsAuthentication.addressRequire"
		/>

		<!-- Zip -->
		<default-input
			v-if="settingsAuthentication.zip"
			v-model="form.zip"
			:label="$t('input.zip') + (settingsAuthentication.zipRequire ? '*' : '')"
			:required="settingsAuthentication.zipRequire"
		/>

		<!-- City -->
		<default-input
			v-if="settingsAuthentication.city"
			v-model="form.city"
			:label="
				$t('input.city') + (settingsAuthentication.cityRequire ? '*' : '')
			"
			:required="settingsAuthentication.cityRequire"
		/>

		<!-- Country -->
		<default-input
			v-if="settingsAuthentication.country"
			v-model="form.country"
			:label="
				$t('input.country') + (settingsAuthentication.countryRequire ? '*' : '')
			"
			:required="settingsAuthentication.countryRequire"
		/>

		<!-- Custom 1 -->
		<default-input
			v-if="settingsAuthentication.custom1"
			v-model="form.custom1"
			:label="
				settingsAuthentication.custom1Name +
				(settingsAuthentication.custom1Require ? '*' : '')
			"
			:required="settingsAuthentication.custom1Require"
		/>

		<!-- Custom 2 -->
		<default-input
			v-if="settingsAuthentication.custom2"
			v-model="form.custom2"
			:label="
				settingsAuthentication.custom2Name +
				(settingsAuthentication.custom2Require ? '*' : '')
			"
			:required="settingsAuthentication.custom2Require"
		/>

		<!-- Custom 3 -->
		<default-input
			v-if="settingsAuthentication.custom3"
			v-model="form.custom3"
			:label="
				settingsAuthentication.custom3Name +
				(settingsAuthentication.custom3Require ? '*' : '')
			"
			:required="settingsAuthentication.custom3Require"
		/>

		<!-- Password -->
		<default-input
			v-model="form.password"
			type="password"
			:label="$t('input.password') + '*'"
			required
			:error="errors.password"
		/>

		<!-- Password confirmation -->
		<default-input
			v-model="form.passwordConfirmation"
			type="password"
			:label="$t('input.password-confirm') + '*'"
			required
			:error="errors.password"
		/>

		<!-- Terms & conditions -->
		<custom-checkbox
			v-if="settingsAuthentication.tac"
			type="tac"
			:content="directory.tac"
		/>

		<!-- Privacy policy -->
		<custom-checkbox
			v-if="settingsAuthentication.privacyPolicy"
			type="privacy"
			:content="directory.privacyPolicy"
		/>

		<v-btn
			type="submit"
			class="text-none mt-6"
			:style="buttonStyle"
			large
			block
			depressed
			:loading="loading"
		>
			{{ $t("authentication.create-account") }}
		</v-btn>

		<div class="text-center mt-5">
			<label class="text--primary">
				{{ $t("authentication.already-member") }}
				<a @click="$emit('openLogin')">
					{{ $t("authentication.sign-in") }}
				</a>
			</label>
		</div>
	</form>
</template>

<script>
import DefaultInput from "@/components/input/DefaultInput.vue";
import DefaultPhoneInput from "@/components/input/DefaultPhoneInput.vue";
import DefaultDatePicker from "@/components/input/DefaultDatePicker.vue";
import CustomCheckbox from "@/components/input/CustomCheckbox.vue";

export default {
	name: "RegisterScreen",
	data() {
		return {
			form: {
				email: null,
				password: null,
				passwordConfirmation: null,
				firstName: null,
				lastName: null,
				phone: null,
				birthDate: null,
				address: null,
				zip: null,
				city: null,
				country: null,
				custom1: null,
				custom2: null,
				custom3: null,
			},

			loading: false,

			phoneObject: null,

			errors: {
				email: null,
				password: null,
				phone: null,
			},
		};
	},
	components: {
		DefaultInput,
		DefaultPhoneInput,
		DefaultDatePicker,
		CustomCheckbox,
	},
	computed: {
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		settingsAuthentication() {
			return this.$store.getters["directory/getDirectory"]
				.settingsAuthentication;
		},
		buttonStyle() {
			return {
				color: this.settingsAuthentication.buttonTextColor + " !important",
				backgroundColor: this.settingsAuthentication.buttonBackgroundColor,
			};
		},
	},
	methods: {
		register() {
			this.resetErrors();

			if (!this.phoneValidation()) return;

			this.loading = true;

			this.$store
				.dispatch("auth/register", this.form)
				.then(() => {
					this.$store
						.dispatch("auth/login", {
							email: this.form.email,
							password: this.form.password,
						})
						.then(() => {
							location.reload();
						});
				})
				.catch((error) => {
					let errors = error.errors;

					for (const [key, value] of Object.entries(errors)) {
						this.errors[key] = value[0];
					}

					this.loading = false;
				});
		},

		phoneValidation() {
			if (!this.settingsAuthentication.requirePhone) return true;

			if (!this.validPhone()) {
				this.errors.phone = this.$t("invalid-phone");
				return false;
			}

			return true;
		},

		validPhone() {
			if (this.phoneObject == null) return false;

			return this.phoneObject.valid ? true : false;
		},

		resetErrors() {
			this.errors = {
				email: null,
				password: null,
				phone: null,
			};
		},
	},
};
</script>
import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const paymentsModule = {
	namespaced: true,
	state: {
		parentUrl: null
	},
	mutations: {
		// Used for payments inside an iframe
		// Stripe Checkout needs to return (success_url & cancel_url) to parent and not directory itself
		STORE_PARENT_URL(state, url) {
			state.parentUrl = url;
		}
	},
	actions: {
		createStripeSession({ rootGetters, state }, paymentOptionId) {
			const params = {
				id: rootGetters["directory/getId"],
				option: paymentOptionId,
				back_url: state.parentUrl
			};

			return new Promise((resolve, reject) => {
				axios.get("/payment/stripe/create-session", {
					params: params,
					headers: headers(rootGetters)
				})
					.then((resp) => {
						resolve(resp.data.url);
					})
					.catch(() => {
						reject();
					});
			});
		},
		createStripePortalSession({ rootGetters, state }) {
			const params = {
				id: rootGetters["directory/getId"],
				back_url: state.parentUrl
			};

			return new Promise((resolve, reject) => {
				axios.get("/payment/stripe/create-portal-session", {
					params: params,
					headers: headers(rootGetters)
				})
					.then((resp) => {
						resolve(resp.data.url);
					})
					.catch(() => {
						reject();
					});
			});
		},
		retrieveStripePaymentStatus(context, sessionId) {
			const params = { session_id: sessionId };

			return new Promise((resolve, reject) => {
				axios.get("/payment/stripe/status", { params: params })
					.then((resp) => {
						resolve(resp.data);
					})
					.catch(() => {
						reject();
					});
			});
		},

		createPaystackSession({ rootGetters, state }, { paymentOptionId, channel }) {
			const params = {
				id: rootGetters["directory/getId"],
				option: paymentOptionId,
				back_url: state.parentUrl,
				channel: channel
			};

			return new Promise((resolve, reject) => {
				axios.get("/payment/paystack/create-session", {
					params: params,
					headers: headers(rootGetters)
				})
					.then((resp) => {
						resolve(resp.data.url);
					})
					.catch(() => {
						reject();
					});
			});
		},
		retrievePaystackPaymentStatus(context, sessionId) {
			return new Promise((resolve, reject) => {
				axios.get(`/payment/paystack/status/${sessionId}`)
					.then((resp) => {
						resolve(resp.data);
					})
					.catch(() => {
						reject();
					});
			});
		},

		redeemPromoCode({ rootGetters }, promoCode) {
			const payload = { code: promoCode };

			return new Promise((resolve, reject) => {
				axios.post("/payment/promocode/redeem-code?id=" + rootGetters["directory/getId"],
					payload,
					{ headers: headers(rootGetters) })
					.then(() => {
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
	getters: {},
};

function headers(getters) {
	return { Authorization: `Bearer ${getters["auth/getAccessToken"]}` }
}

export default paymentsModule;

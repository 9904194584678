<template>
    <div id="forgot-password">
        <div class="mt-2 mb-4 text--primary">
            {{ $t("authentication.forgot-password-description") }}
        </div>

        <v-alert v-if="success" type="success" text dense>
            <label class="body-2">
                {{ success }}
            </label>
        </v-alert>

        <form v-on:submit.prevent="requestPasswordReset">
            <default-input
                v-model="email"
                :label="$t('input.email')"
                :error="error"
                required
            />

            <v-btn
                type="submit"
                class="text-none"
                :style="buttonStyle"
                large
                block
                depressed
                :loading="loading"
            >
                {{ $t("button.continue") }}
            </v-btn>

            <!-- Back to login -->
            <div class="text-center mt-5">
                <a @click="$emit('openLogin')">{{
                    $t("authentication.back-to-sign-in")
                }}</a>
            </div>
        </form>
    </div>
</template>

<script>
import DefaultInput from "@/components/input/DefaultInput.vue";

export default {
    name: "ForgotPassword",
    components: {
        DefaultInput,
    },
    data() {
        return {
            email: null,
            loading: false,

            success: null,
            error: null,
        };
    },
    methods: {
        requestPasswordReset() {
            this.loading = true;
            this.success = null;
            this.error = null;

            this.$store
                .dispatch("auth/forgotPassword", { email: this.email })
                .then(() => {
                    this.success = this.$t(
                        "authentication.request-new-password-success"
                    );
                })
                .catch(() => {
                    this.error = this.$t(
                        "authentication.request-new-password-error"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    computed: {
        authentication() {
            return this.$store.getters["directory/getDirectory"]
                .settingsAuthentication;
        },
        buttonStyle() {
            return {
                color: this.authentication.buttonTextColor + " !important",
                backgroundColor: this.authentication.buttonBackgroundColor,
            };
        },
    },
};
</script>
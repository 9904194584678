<template>
    <div class="preview-phone">
        <div class="preview-phone__wrapper">
            <div
                style="
                    width: 367px;
                    position: relative;
                    display: inline-block;
                    margin-left: 10px;
                "
            >
                <img
                    src="@/assets/images/phone.jpg"
                    class="preview-phone__image"
                />

                <div class="preview-phone__content" scrolling="no">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PreviewPhone",
};
</script>

<style scoped>
.preview-phone {
    position: relative;
    width: 100%;
}

.preview-phone__wrapper {
    position: absolute;
    width: 100%;
    right: 10px;
}

.preview-phone__image {
    height: 705px;
    width: 367px;
}

.preview-phone__content {
    background-color: white;
    top: 70px;
    left: 16px;
    right: 38px;
    bottom: 52px;
    position: absolute;
}
</style>

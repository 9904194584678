import DistanceUnit from "../enums/DistanceUnit";

export default {
	methods: {
		calculateDistances(cards, currLocation) {
			for (let i = 0; i < cards.length; ++i) {
				let closest = null;

				// This can be removed in future when we auto remove coupons from a directory if coupon is deleted
				if (cards[i].campaign) {
					let locations = cards[i].campaign.locations;

					for (let j = 0; j < locations.length; ++j) {
						if (locations[j].latitude && locations[j].longitude) {
							locations[j].distance = this.getDistanceBetween(
								locations[j].latitude,
								locations[j].longitude,
								currLocation[0],
								currLocation[1]
							);

							if (!closest || closest.distance > locations[j].distance)
								closest = locations[j];
						} else {
							locations[j].distance = null;
						}
					}
				}

				cards[i].closest = closest;
			}

			return cards;
		},

		// Get distance between 2 coordinates in km
		getDistanceBetween(lat1, lon1, lat2, lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = this.degreeToRadius(lat2 - lat1);
			var dLon = this.degreeToRadius(lon2 - lon1);

			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(this.degreeToRadius(lat1)) *
				Math.cos(this.degreeToRadius(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			var d = R * c; // Distance in km

			return d;
		},

		degreeToRadius(deg) {
			return deg * (Math.PI / 180);
		},

		parseDistance(distance, metric) {
			// Convert km to miles
			if (metric == DistanceUnit.MILES) distance = distance * 0.621371 // calculate miles

			let rounded = Math.round(distance * 10) / 10;

			return rounded.toFixed(1) + " " + metric;
		}
	}
};
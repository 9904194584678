<template>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-card-title class="justify-center">
                <v-btn
                    class="dialog__close"
                    icon
                    x-small
                    @click.native="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <label v-html="info" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "InfoDialog",
    props: {
        info: String,
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        open() {
            this.dialog = true;
        },
    },
};
</script>
<template>
	<div id="filter" class="float-right">
		<div id="filter-buttons" class="hidden-sm-and-down">
			<button
				v-for="filter in filters"
				:key="filter.value"
				class="filter-button ml-2"
				@click="toggleFilter(filter.value)"
				:style="filterStyle(filter.value)"
			>
				{{ filter.name }}
			</button>

			<button
				v-if="selected.length"
				class="reset-button ml-2"
				@click="selected = []"
				:style="filterStyle()"
			>
				<v-icon :style="resetFilterButtonStyle">mdi-close</v-icon>
			</button>
		</div>

		<v-menu offset-y>
			<template v-slot:activator="{ attrs, on }">
				<v-icon
					id="filter-menu-icon"
					class="hidden-md-and-up"
					v-bind="attrs"
					v-on="on"
					:color="selected.length ? 'green' : filterButtonColor"
				>
					mdi-tune-variant
				</v-icon>
			</template>

			<v-list class="pa-0" dense>
				<v-list-item
					v-for="filter in filters"
					:key="filter.value"
					link
					@click="toggleFilter(filter.value)"
				>
					<v-icon
						v-if="selected.includes(filter.value)"
						class="mr-2"
						small
						color="black"
					>
						mdi-check
					</v-icon>

					<v-list-item-title>
						{{ filter.name }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: "Filters",
	data() {
		return {
			filters: [],
			selected: [],
		};
	},
	mounted() {
		if (this.categorySettings.filterRecommended) {
			this.filters.push({
				name: this.cardSettings.recommendedLabel, // Name is customizable
				value: "recommended",
			});
		}

		const favoritesFilter =
			this.directory.settingsAuthentication.requireAuthentication &&
			this.categorySettings.filterFavorites;

		if (favoritesFilter) {
			this.filters.push({
				name: this.$t("filters.favorites"), // Name is customizable
				value: "favorites",
			});
		}

		if (this.categorySettings.filterClaimed) {
			this.filters.push({
				name: this.$t("filters.claimed"),
				value: "claimed",
			});
		}

		if (this.categorySettings.filterValidated) {
			this.filters.push({
				name: this.$t("filters.validated"),
				value: "validated",
			});
		}
	},
	computed: {
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		categorySettings() {
			return this.directory.settingsCategory;
		},
		cardSettings() {
			return this.directory.settingsCard;
		},
		filterButtonColor() {
			return this.categorySettings.topTextColor;
		},
		resetFilterButtonStyle() {
			let fontSize = this.categorySettings.topFontSize + 4;

			return {
				color: this.filterButtonColor,
				fontSize: fontSize + "px",
			};
		},
		coordinates() {
			return this.$store.getters["user/getCoordinates"];
		},
	},
	methods: {
		toggleFilter(value) {
			const index = this.selected.indexOf(value);
			if (index > -1) {
				this.selected.splice(index, 1);
			} else {
				this.selected.push(value);
			}
		},
		filterStyle(value) {
			const filterSelected = this.selected.includes(value);

			let style = {
				color: this.categorySettings.topTextColor,
				backgroundColor: this.categorySettings.topBackgroundColor,
				fontSize: this.categorySettings.topFontSize + "px",
				borderRadius: this.categorySettings.topBorderRadius + "px",
				borderWidth: this.categorySettings.topBorderThickness + "px",
			};

			style.fontWeight = filterSelected
				? 700
				: this.categorySettings.topFontWeight;

			// Inset border to prevent layout push
			if (filterSelected)
				style.boxShadow = `inset 0 0 0 1px ${this.categorySettings.topBorderColor}`;

			return style;
		},
	},
	watch: {
		selected: {
			handler() {
				this.$emit("filter", this.selected);

				// Set page back to start
				this.$store.commit("directory/SET_CURRENT_PAGE", 1);
			},
		},
		coordinates: {
			handler() {
				// Add "Close by" filter option when geolocation permissions have been granted
				const filterCloseBy =
					this.directory.geolocation &&
					this.categorySettings.filterCloseBy;
				if (!filterCloseBy) return;

				const index = this.filters.findIndex((element) => {
					return element.value === "close-by";
				});

				if (index === -1) {
					this.filters.push({
						name: this.$t("filters.close-by"),
						value: "close-by",
					});
				}
			},
		},
	},
};
</script>

<style scoped>
.reset-button {
	min-width: unset !important;
	padding: 2px 4px 2px 4px;
	border: solid;
}

.filter-button {
	text-transform: uppercase !important;
	padding: 2px 10px 2px 10px;
	border: solid;
}

.filter-button:hover,
.reset-button:hover {
	background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
</style>
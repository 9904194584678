function toCamelCase(obj) {
	if (obj == null) return obj;

	let rtn = obj
	if (typeof (obj) === 'object') {
		if (obj instanceof Array) {
			rtn = obj.map(toCamelCase)
		} else {
			rtn = {}
			for (let key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					const newKey = key.replace(/(_\w)/g, k => k[1].toUpperCase())
					rtn[newKey] = toCamelCase(obj[key])
				}
			}
		}
	}
	return rtn
}

function toSnakeCase(obj) {
	if (typeof (obj) != "object") return obj;

	for (var oldName in obj) {

		// Camel to underscore
		let newName = oldName.replace(/([A-Z\d])/g, function ($1) { return "_" + $1.toLowerCase(); });

		// Only process if names are different
		if (newName != oldName) {
			// Check for the old property name to avoid a ReferenceError in strict mode.
			if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
				obj[newName] = obj[oldName];
				delete obj[oldName];
			}
		}

		// Recursion
		if (typeof (obj[newName]) == "object") {
			obj[newName] = toSnakeCase(obj[newName]);
		}

	}
	return obj;
}

export { toCamelCase, toSnakeCase }


<template>
    <div id="actions" class="d-flex flex-grow-1">
        <!-- View/redeem button -->
        <button
            v-if="cardSettings.showRedeem"
            class="redeem"
            :style="buttonStyle"
            :loading="loadingProp"
            :disabled="loadingState"
            @click.prevent.stop="redeemClicked"
        >
            <v-progress-circular
                v-if="loadingProp"
                indeterminate
                :size="20"
                :width="2"
            ></v-progress-circular>

            <label v-else>{{ cardSettings.buttonText }}</label>
        </button>

        <!-- Location button -->
        <button
            v-if="
                cardSettings.showLocation && card.campaign.locations.length != 0
            "
            class="redeem ml-2 pl-2 pr-2"
            :style="buttonStyleIgnoreWidth"
            @click.prevent.stop="openLocation()"
        >
            <v-icon :color="cardSettings.buttonTextColor">
                mdi-map-marker
            </v-icon>

            <small v-if="card.closest">
                {{
                    parseDistance(card.closest.distance, directory.distanceUnit)
                }}
            </small>
        </button>

        <!-- Information button -->
        <button
            v-if="cardSettings.showInformation"
            class="redeem ml-2 pl-2 pr-2"
            :style="buttonStyleIgnoreWidth"
            @click.prevent.stop="$refs.infoDialog.open()"
        >
            <v-icon :color="cardSettings.buttonTextColor">
                mdi-information-variant
            </v-icon>
        </button>

        <info-dialog
            v-if="cardSettings.showInformation"
            :info="card.campaign.description"
            ref="infoDialog"
        />
    </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { redeem } from "@/services/redeem.js";

import locationHelpers from "@/mixins/locationHelpers.js";
import layoutHelpers from "@/mixins/layoutHelpers.js";

import InfoDialog from "@/components/cards/InfoDialog.vue";

export default {
    name: "CardActions",
    props: {
        card: Object,
        loading: Boolean,
    },
    components: {
        InfoDialog,
    },
    data() {
        return {
            loadingProp: this.loading,
        };
    },
    mixins: [locationHelpers, layoutHelpers],
    computed: {
        directory() {
            return this.$store.getters["directory/getDirectory"];
        },
        authenticationSettings() {
            return this.directory.settingsAuthentication;
        },
        cardSettings() {
            return this.directory.settingsCard;
        },
        loadingState() {
            return this.$store.getters["cards/loading"];
        },
    },
    mounted() {
        EventBus.$on("reset-loading", () => {
            this.loadingProp = false;
        });
    },
    methods: {
        redeemClicked() {
            this.loadingProp = true;

            redeem(this.card);
        },
        openLocation() {
            let flyTo = null;

            if (this.card.closest) {
                flyTo = this.card.closest;
            } else if (this.card.campaign.locations.length == 1) {
                flyTo = this.card.campaign.locations[0];
            }

            EventBus.$emit("open-locations-dialog", this.card.id, flyTo);
        },
    },
    watch: {
        loading: function () {
            this.loadingProp = this.loading;
        },
    },
};
</script>
<template>
    <v-dialog v-model="dialog" width="460">
        <v-card v-if="user">
            <v-card-title class="justify-center">
                <v-btn
                    class="dialog__close"
                    icon
                    x-small
                    @click.native="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <label>{{ $t("authentication.profile") }}</label>
            </v-card-title>

            <v-card-text>
                <form v-on:submit.prevent="save">
                    <v-row>
                        <!-- Email -->
                        <v-col cols="12" class="pb-0">
                            <default-input
                                v-model="user.email"
                                :label="$t('input.email')"
                                required
                                disabled
                            />
                        </v-col>

                        <!-- First name -->
                        <v-col cols="6" class="pb-0 pt-0">
                            <default-input
                                v-model="user.firstName"
                                :label="$t('input.first-name')"
                                :required="
                                    settingsAuthentication.firstNameRequire
                                "
                            />
                        </v-col>

                        <!-- Last name -->
                        <v-col cols="6" class="pb-0 pt-0">
                            <default-input
                                v-model="user.lastName"
                                :label="$t('input.last-name')"
                                :required="
                                    settingsAuthentication.lastNameRequire
                                "
                            />
                        </v-col>

                        <!-- Phone -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <default-phone-input
                                v-model="user.phone"
                                @object="(object) => (phoneObject = object)"
                                :label="$t('input.phone')"
                                :required="settingsAuthentication.phoneRequire"
                            />
                        </v-col>

                        <!-- Date picker -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <default-date-picker
                                v-model="user.birthDate"
                                :label="$t('input.birth-date')"
                                :required="
                                    settingsAuthentication.birthDateRequire
                                "
                            />
                        </v-col>

                        <!-- Address -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <default-input
                                v-model="user.address"
                                :label="$t('input.address')"
                                :required="
                                    settingsAuthentication.addressRequire
                                "
                            />
                        </v-col>

                        <!-- City -->
                        <v-col cols="6" class="pb-0 pt-0">
                            <default-input
                                v-model="user.city"
                                :label="$t('input.city')"
                                :required="settingsAuthentication.cityRequire"
                            />
                        </v-col>

                        <!-- Zip -->
                        <v-col cols="6" class="pb-0 pt-0">
                            <default-input
                                v-model="user.zip"
                                :label="$t('input.zip')"
                                :required="settingsAuthentication.zipRequire"
                            />
                        </v-col>

                        <!-- Country -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <default-input
                                v-model="user.country"
                                :label="$t('input.country')"
                                :required="
                                    settingsAuthentication.countryRequire
                                "
                            />
                        </v-col>

                        <v-col cols="12" class="pb-0 pt-0">
                            <v-btn
                                type="submit"
                                color="success"
                                large
                                block
                                depressed
                                :loading="saving"
                            >
                                <v-icon class="mr-2" dense>
                                    mdi-content-save
                                </v-icon>
                                {{ $t("button.save") }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <a
                        @click="$refs.deleteConfirmation.open()"
                        :disabled="saving"
                        class="text-decoration-underline red--text mt-2"
                    >
                        {{ $t("authentication.delete-account") }}
                    </a>
                </form>
            </v-card-text>
        </v-card>

        <delete-confirmation ref="deleteConfirmation" />

        <!-- Saved snackbar -->
        <v-snackbar
            v-model="snackbar"
            bottom
            right
            :timeout="4000"
            :color="snackbarColor"
        >
            <v-icon dark class="mr-2">
                {{ snackbarIcon }}
            </v-icon>

            {{ snackbarText }}
        </v-snackbar>
    </v-dialog>
</template>

<script>
import DefaultInput from "@/components/input/DefaultInput.vue";
import DefaultPhoneInput from "@/components/input/DefaultPhoneInput.vue";
import DefaultDatePicker from "@/components/input/DefaultDatePicker.vue";

import DeleteConfirmation from "@/components/authentication/DeleteConfirmation.vue";

export default {
    name: "Profile",
    data() {
        return {
            dialog: false,
            user: null,
            saving: false,

            snackbar: false,
            snackbarText: null,
            snackbarColor: null,
            snackbarIcon: null,
        };
    },
    components: {
        DefaultInput,
        DefaultPhoneInput,
        DefaultDatePicker,
        DeleteConfirmation,
    },
    computed: {
        settingsAuthentication() {
            return this.$store.getters["directory/getDirectory"]
                .settingsAuthentication;
        },
    },
    mounted() {
        this.user = this.$store.getters["user/getUser"];
    },
    methods: {
        open() {
            this.dialog = true;
        },
        save() {
            this.saving = true;

            this.$store
                .dispatch("user/update", this.user)
                .then(() => {
                    this.snackbarIcon = "mdi-checkbox-marked-circle";
                    this.snackbarColor = "success";
                    this.snackbarText = this.$t(
                        "authentication.account-update-success"
                    );
                })
                .catch(() => {
                    this.snackbarIcon = "mdi-alert-circle";
                    this.snackbarColor = "error";
                    this.$t("authentication.account-update-error");
                })
                .finally(() => {
                    this.saving = false;
                    this.snackbar = true;
                });
        },
    },
};
</script>
import { render, staticRenderFns } from "./CardList.vue?vue&type=template&id=6f3854be&scoped=true&"
import script from "./CardList.vue?vue&type=script&lang=js&"
export * from "./CardList.vue?vue&type=script&lang=js&"
import style0 from "./CardList.vue?vue&type=style&index=0&id=6f3854be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3854be",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardActions,VCardText,VChip,VIcon,VImg})

<template>
	<!-- Masonry layout (https://masonry.desandro.com) can cause sorting to be inaccurate -->
	<v-row
		v-if="settingsCard.masonryLayout"
		v-masonry
		transition-duration="0"
		item-selector=".item"
		v-scroll="handleScroll"
	>
		<v-col
			v-masonry-tile
			v-for="card in lazyCards"
			:key="card.id"
			class="item"
			cols="12"
			:sm="isTile ? '6' : '12'"
			:md="mdCardSize"
			:lg="lgCardSize"
		>
			<v-lazy
				v-if="card.campaign"
				:options="{
					threshold: 0.5,
				}"
				transition="fade-transition"
			>
				<component :is="cardType" :card="card" />
			</v-lazy>
		</v-col>
	</v-row>
	<!-- Non-masonry layout -->
	<v-row v-else v-scroll="handleScroll">
		<v-col
			v-for="card in lazyCards"
			:key="card.id"
			class="item"
			cols="12"
			:sm="isTile ? '6' : '12'"
			:md="mdCardSize"
			:lg="lgCardSize"
		>
			<v-lazy
				v-if="card.campaign"
				class="item-lazy-stretch"
				:options="{
					threshold: 0.5,
				}"
				transition="fade-transition"
			>
				<component
					class="item-card-stretch"
					:is="cardType"
					:card="card"
				/>
			</v-lazy>
		</v-col>
	</v-row>
</template>

<script>
import CardTile from "@/components/cards/CardTile.vue";
import CardList from "@/components/cards/CardList.vue";

const CardsPerLoad = 16;

export default {
	name: "ActiveCards",
	props: {
		cards: Array,
	},
	components: {
		CardTile,
		CardList,
	},
	data() {
		return {
			cardType: null,

			lazyCards: [],
			currentLazyLoadIndex: 1,
		};
	},
	mounted() {
		this.cardType = this.isTile ? CardTile : CardList;
	},
	methods: {
		/**
		 * Lazy load cards based on scroll height
		 * Rendering is very slow because of v-masonry (no point in lazy loading server-side)
		 */
		handleScroll(e) {
			if (this.lazyCards.length == this.cards.length) return; // All cards loaded

			if (typeof window === "undefined") return;

			let top = window.pageYOffset || e.target.scrollTop || 0;

			let lazyLoadIndex = Math.ceil(top / this.thresholdByBreakpoint);

			if (lazyLoadIndex <= this.currentLazyLoadIndex) return;

			this.lazyCards = this.cards.slice(0, lazyLoadIndex * CardsPerLoad);

			this.currentLazyLoadIndex = lazyLoadIndex;
		},
	},
	computed: {
		settingsCategory() {
			return this.$store.getters["directory/getDirectory"]
				.settingsCategory;
		},
		settingsCard() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		isTile() {
			return this.settingsCard.type == "tile";
		},
		mdCardSize() {
			const hasLeftCategories = this.settingsCategory.leftCategories;

			if (this.isTile) {
				return hasLeftCategories ? 6 : 4;
			} else {
				return hasLeftCategories ? 12 : 6;
			}
		},
		lgCardSize() {
			const hasLeftCategories = this.settingsCategory.leftCategories;

			if (this.isTile) {
				return hasLeftCategories ? 4 : 3;
			} else {
				return hasLeftCategories ? 6 : 4;
			}
		},
		thresholdByBreakpoint() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return this.isTile ? 2000 : 500;
				case "sm":
					return this.isTile ? 1400 : 350;
				case "md":
					return this.isTile ? 1000 : 250;
			}

			return this.isTile ? 800 : 200;
		},
	},
	watch: {
		cards: {
			handler() {
				this.currentLazyLoadIndex = 1;

				// When cards change, set back to default cards per load
				this.lazyCards = this.cards.slice(0, CardsPerLoad);
			},
			immediate: true,
		},
	},
};
</script>

<style scoped>
.item {
	padding-top: 0px;
	padding-bottom: 20px;
}

.item-lazy-stretch,
.item-card-stretch {
	height: 100%;
}
</style>
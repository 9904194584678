import store from '@/store/index';

export default {
	methods: {
		directoryPaymentSuccess() {
			// Check if authenticated
			if (!store.getters["auth/authenticated"]) return false;

			let payments = store.getters["user/getUser"].payments;

			const now = new Date();

			let directoryPayments = payments.find(obj => {
				return obj.paymentOption.categoryId == null && obj.status == "paid" &&
					(obj.expiresAt == null || obj.expiresAt >= now.toISOString());
			})

			return directoryPayments != null;
		},
		categoryPaymentSuccess(categoryIds) {
			// Check if authenticated
			if (!store.getters["auth/authenticated"]) return false;

			let payments = store.getters["user/getUser"].payments;

			const now = new Date();

			let categoryPayments = payments.find(obj => {
				return categoryIds.includes(obj.paymentOption.categoryId) && obj.status == "paid" &&
					(obj.expiresAt == null || obj.expiresAt >= now.toISOString());
			});

			return categoryPayments != null;
		}
	}
};
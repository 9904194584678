<template>
    <transition name="fade">
        <v-btn
            v-scroll="onScroll"
            v-show="showToTop"
            fab
            dark
            fixed
            bottom
            right
            :small="$vuetify.breakpoint.mobile"
            color="primary"
            @click="scrollToTop"
            style="z-index: 6"
        >
            <v-icon large>mdi-chevron-up</v-icon>
        </v-btn>
    </transition>
</template>

<script>
export default {
    name: "ScrollToTop",
    data() {
        return {
            showToTop: false,
        };
    },
    methods: {
        onScroll(e) {
            if (typeof window === "undefined") return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.showToTop = top > 200;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { toCamelCase } from "../namingConversion.js";

Vue.use(Vuex);

const directoryModule = {
	namespaced: true,
	state: {
		code: null,
		directory: null,
		cards: null,

		initialLeftCategories: null,

		currentCategory: null,
		currentPage: 1,

		screenshot: false,
	},
	mutations: {
		SET_CODE(state, code) {
			state.code = code;
		},

		SET_DIRECTORY(state, directory) {
			state.directory = toCamelCase(directory);

			state.initialLeftCategories = state.directory.settingsCategory.leftCategories;
		},

		SET_CARDS(state, cards) {
			state.cards = cards;
		},

		SET_CURRENT_CATEGORY(state, category) {
			state.currentCategory = category;
		},

		SET_CURRENT_PAGE(state, page) {
			state.currentPage = page;
		},

		UPDATE_LEFT_CATEGORIES(state, value) {
			state.directory.settingsCategory.leftCategories = value;
		},

		SET_SCREENSHOT(state, value) {
			state.screenshot = value;
		}
	},
	actions: {
		load({ commit }, code) {
			commit("SET_CODE", code);

			// Used to prevent API response disk caching
			const timestamp = new Date().getTime();

			return new Promise((resolve, reject) => {
				axios
					.get("/by-code/" + code + "?timestamp=" + timestamp)
					.then((resp) => {
						commit("SET_DIRECTORY", resp.data);

						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
		updateLeftCategoryOnSearch({ commit, state }, value) {
			// Do nothing if directory already has desktop category disabled
			if (!state.initialLeftCategories) return;

			commit("UPDATE_LEFT_CATEGORIES", value);
		}
	},
	getters: {
		getId(state) {
			return state.directory.id;
		},
		getDirectory(state) {
			return state.directory;
		},
		getCategories(state) {
			return state.directory.categories;
		},
		getCurrentCategory(state) {
			return state.currentCategory;
		},
		category(state) {
			return (categoryId) => {
				return state.directory.categories.find((category) => category.id === categoryId);
			}
		},
		getCurrentPage(state) {
			return state.currentPage;
		},
		isScreenshot(state) {
			return state.screenshot;
		},
		directoryDateFormat(state) {
			return state.directory.dateFormat;
		}
	},
};

export default directoryModule;
